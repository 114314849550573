export enum RouteParameters {
    projectId = ':projectId',
    drillholeId = ':drillholeId',
    tableViewId = ':tableViewId',
    pointId = ':pointId',
    listId = ':listId',
    tableId = ':tableId',
    headerId = ':headerId',
    fieldId = ':fieldId',
    activityId = ':activityId',
    gridId = ':gridId',
    activityTableId = ':activityTableId',
    sampleWorkflowId = ':sampleWorkflowId',
    validationRuleId = ':validationRuleId',
}
export const PathConstants = {
    BASE: {
        ROOT: '/',
    },
    HOME: {
        ROOT: '/home',
        PREFERENCES: {
            ROOT: 'preferences',
            DATA_ENTRY: {
                ROOT: 'data-entry',
            },
            SUBSCRIPTIONS: {
                ROOT: 'subscriptions',
            },
        },
    },
    REFRESH_TOKEN_PATH: {
        ROOT: '/refresh_token',
    },
    LOGIN: {
        ROOT: '/login',
    },
    PROJECTS: {
        ROOT: '/projects',
        DRILLHOLE: {
            ROOT: 'drillhole',
            TABLE_VIEW: {
                ROOT: 'view',
            },
            IMPORT: {
                ROOT: 'import',
            },
        },
        POINTS: {
            ROOT: 'points',
            TABLE_VIEW: {
                ROOT: 'view',
            },
            IMPORT: {
                ROOT: 'import',
            },
        },
    },
    EVENTS: {
        ROOT: '/events',
        ALLEVENTS: {
            ROOT: 'all-events',
        },
        POSTS: {
            ROOT: 'posts',
        },
        JOBS: {
            ROOT: 'jobs',
        },
    },
    CONFIGURATION: {
        ROOT: '/configuration',
        LOGGING: {
            ROOT: 'logging',
            LISTS: {
                ROOT: 'lists',
                CREATE: {
                    ROOT: 'create',
                },
                IMPORT: {
                    ROOT: 'bulk-list',
                },
            },
            TABLES: {
                ROOT: 'tables',
                CREATE: {
                    ROOT: 'create',
                    TABLE_VIEWS: {
                        ROOT: 'table-views-create',
                    },
                },
            },
            FIELDS: {
                ROOT: 'fields',
            },
            HEADERS: {
                ROOT: 'headers',
                CREATE: {
                    ROOT: 'create',
                },
            },
        },
        ACTIVITIES_PROJECTS: {
            ROOT: 'activity-project',
            ACTIVITIES: {
                ROOT: 'activities',
                CREATE: {
                    ROOT: 'create',
                },
            },
            ACTIVITY_GROUPS: {
                ROOT: 'activity-groups',
            },
            PROJECTS: {
                ROOT: 'projects',
                CREATE: {
                    ROOT: 'create',
                },
            },
            GRIDS: {
                ROOT: 'grids',
                CREATE: {
                    ROOT: 'create',
                },
                SELECT: {
                    ROOT: 'select-from-list',
                },
            },
        },
        SAMPLING: {
            ROOT: 'sampling',
            LISTS: {
                ROOT: 'sample-lists',
            },
            DISPATCH_FIELDS: {
                ROOT: 'dispatch-fields',
            },
            DISPATCH_HEADERS: {
                ROOT: 'dispatch-headers',
                CREATE: {
                    ROOT: 'create',
                },
            },
            ANALYSIS_WORKFLOWS: {
                ROOT: 'analysis-workflows',
                CREATE: {
                    ROOT: 'create',
                },
            },
        },
        ACCOUNT: {
            ROOT: 'account',
            USERS: {
                ROOT: 'users',
            },
            CATEGORIES: {
                ROOT: 'categories',
            },
        },
        INTEGRATIONS: {
            ROOT: 'integrations',
            EVO: {
                ROOT: 'evo',
            },
        },
        VALIDATION_AND_WORKFLOWS: {
            ROOT: 'validations-and-workflows',
            VALIDATION_RULES: {
                ROOT: 'validation-rules',
                CREATE: {
                    ROOT: 'create',
                },
            },
        },
    },
    NOTIFICATIONS: {
        ROOT: '/notifications',
    },
    LOGOUT: {
        ROOT: '/logout',
    },
    LOGOUT_SUCCESS: {
        ROOT: '/logout_success',
    },
};
