export const i18nStatusConstants: Record<string, { id: string; defaultMessage: string }> = {
    statusInprogress: { defaultMessage: 'In Progress', id: 'status.inprogress' },
    statusNew: { defaultMessage: 'New', id: 'status.new:' },
    statusSent: { defaultMessage: 'Sent', id: 'status.sent' },
    statusReceived: { defaultMessage: 'Received', id: 'status.received' },
    statusImported: { defaultMessage: 'Imported', id: 'status.imported' },
    statusAcceptedWithFailure: {
        defaultMessage: 'Accepted with failure',
        id: 'status.acceptedWithFailure',
    },
    statusWarning: { defaultMessage: 'Warning', id: 'status.warning:' },
    statusFailed: { defaultMessage: 'Failed', id: 'status.failed' },
    statusUndefined: { defaultMessage: 'Undefined', id: 'status.undefined' },
    statusPassed: { defaultMessage: 'Passed', id: 'status.passed' },
    statusNotReleased: { defaultMessage: 'Not released', id: 'status.notReleased' },
    statusReleased: { defaultMessage: 'Released', id: 'status.released' },

    // from old code
    statusPending: { defaultMessage: 'Pending', id: 'status.pending' },
    statusOnHold: { defaultMessage: 'On Hold', id: 'status.onHold' },
    statusComplete: { defaultMessage: 'Complete', id: 'status.complete' },
    statusZeroState: { defaultMessage: 'Zero State', id: 'status.zeroState' },
};

export const i18nActionConstants: Record<string, { id: string; defaultMessage: string }> = {
    ok: { defaultMessage: 'Ok', id: 'action.ok' },
    add: { defaultMessage: 'Add', id: 'action.add' },
    new: { defaultMessage: 'New', id: 'action.new' },
    select: { defaultMessage: 'Select', id: 'action.select' },
    clone: { defaultMessage: 'Clone', id: 'action.clone' },
    create: { defaultMessage: 'Create', id: 'action.create' },
    edit: { defaultMessage: 'Edit', id: 'action.edit' },
    change: { defaultMessage: 'Change', id: 'action.change' },
    update: { defaultMessage: 'Update', id: 'action.update' },
    assign: { defaultMessage: 'Assign', id: 'action.assign' },
    associate: { defaultMessage: 'Associate', id: 'action.associate' },
    delete: { defaultMessage: 'Delete', id: 'action.delete' },
    remove: { defaultMessage: 'Remove', id: 'action.remove' },
    cancel: { defaultMessage: 'Cancel', id: 'action.cancel' },
    apply: { defaultMessage: 'Apply', id: 'action.apply' },
    import: { defaultMessage: 'Import', id: 'action.import' },
    export: { defaultMessage: 'Export', id: 'action.export' },
    saveAndGoBack: { defaultMessage: 'Save and Go Back', id: 'action.save-and-go-back' },
    goBack: { defaultMessage: 'Go Back', id: 'action.go-back' },
    navigationWarning: {
        defaultMessage:
            'You are navigating away from this page with unsaved changes. Do you want to save the changes?',
        id: 'action.navigation-warning',
    },
    saveChanges: { defaultMessage: 'Save Changes', id: 'action.save-changes' },
    post: { defaultMessage: 'Post', id: 'action.post' },
    link: { defaultMessage: 'Link', id: 'action.link' },
};
