import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildLinkedObjectUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { PostLinkedObjectType } from 'state-domains/domain/events';

import { sendRetrieveRequest } from '../../../utils';
import { EventsObject } from '../types';

export const loadLinkedObjects = (
    type: PostLinkedObjectType,
    projectId: string,
    activityId: string,
): Observable<{ count: number; Event: EventsObject[] }> => {
    const urlType = {
        [PostLinkedObjectType.DRILLHOLE]: 'collars',
        [PostLinkedObjectType.POINT]: 'points',
        [PostLinkedObjectType.DISPATCH]: 'sample-dispatch',
        [PostLinkedObjectType.LAB_CERT]: 'lab-certificates',
    };

    const showSample =
        type === PostLinkedObjectType.DISPATCH || type === PostLinkedObjectType.LAB_CERT;

    const obs = sendRetrieveRequest(
        buildLinkedObjectUrl(urlType[type], projectId, JSON.stringify([activityId]), showSample),
    );

    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            return observableOf(convertToCamel<any>(response.data));
        }),
    );
};
