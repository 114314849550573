import { makeStyles } from 'tss-react/mui';
import { mainFontTypes } from 'src/styles';

export const styles = () =>
    ({
        linkText: {
            ...mainFontTypes,
            fontSize: '12px',
            color: '#265C7F',
            textDecoration: 'underline',
        },
        flexEllipsis: {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
