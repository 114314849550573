import { makeStyles } from 'tss-react/mui';
import { commonStyles, mainFontTypes } from 'src/styles';
import { theme } from '@local/web-design-system';

export const styles = () =>
    ({
        basicText: {
            ...mainFontTypes,
            fontSize: '12px',
        },
        dimText: {
            ...mainFontTypes,
            fontSize: '12px',
            color: '#8E9095',
        },
        linkText: {
            ...mainFontTypes,
            fontSize: '12px',
            color: '#265C7F',
            textDecoration: 'underline',
        },
        notificationMessage: {
            ...commonStyles(theme).notificationMessage,
        },
    }) as const;
export const useStyles = makeStyles()(styles);
