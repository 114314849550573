export const imageContentTypes = new Set([
    'image/bmp',
    'image/cis-cod',
    'image/gif',
    'image/ief',
    'image/jpeg',
    'image/pipeg',
    'image/svg+xml',
    'image/tiff',
    'image/x-cmu-raster',
    'image/x-cmx',
    'image/x-icon',
    'image/x-portable-anymap',
    'image/x-portable-bitmap',
    'image/x-portable-graymap',
    'image/x-portable-pixmap',
    'image/x-rgb',
    'image/x-xbitmap',
    'image/x-xpixmap',
    'image/apng',
    'image/avif',
    'image/png',
    'image/webp',
]);
export const editableDocContentTypes = new Set([
    'text/plain',
    'application/x-abiword',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/rtf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]);
export const portableDocContentTypes = new Set(['application/pdf']);
