import { mainFontTypes } from 'src/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = () =>
    ({
        dimText: {
            ...mainFontTypes,
            fontSize: '12px',
            color: '#8E9095',
        },
        linkText: {
            ...mainFontTypes,
            fontSize: '12px',
            color: '#265C7F',
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        postButton: {
            width: '10px',
            height: '40px',
        },
        arrow: {
            cursor: 'pointer',
        },
    }) as const;
export const useStyles = makeStyles()(styles);
