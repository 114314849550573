import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { CreatePostPayload, EventsObject } from 'state-domains/domain';

import { buildPostUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const createPost = (payload: CreatePostPayload): Observable<{ event: EventsObject }> => {
    return sendRequestWithXSRFToken(buildPostUrl(), payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<EventsObject>) => {
            return observableOf(convertToCamel<{ event: EventsObject }>(response));
        }),
    );
};
