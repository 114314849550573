import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { useStyles } from './Comments.styles';
import { i18n } from './Comments.i18n';

export const ReplyButton = ({ id, setCommentedId }: { id: string; setCommentedId: Function }) => {
    const { classes } = useStyles();
    const intl = useIntl();

    return (
        <Typography className={classes.linkText} onClick={() => setCommentedId(id)}>
            {intl.formatMessage(i18n.replyButton)}
        </Typography>
    );
};
