import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildUpdateJobUrl } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const updateJob = (payload: any): Observable<any> => {
    const url = buildUpdateJobUrl(payload.id);
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const result = convertToCamel(response);
            return observableOf(result);
        }),
    );
};
