import { theme } from '@local/web-design-system';
import { Grid, LinearProgress } from '@mui/material';

export const DialogProgressBar = (props: { isPending: boolean }) => {
    const { isPending } = props;
    return (
        <>
            {isPending && (
                <Grid item sx={{ width: '100%' }}>
                    <LinearProgress
                        color="primary"
                        sx={{
                            barColorPrimary: theme.palette.common.white,
                            colorPrimary: theme.palette.primary.main,
                            width: '100%',
                            height: theme.spacing(1),
                        }}
                    />
                </Grid>
            )}
        </>
    );
};
