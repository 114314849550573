import { fonts } from '@local/web-design-system';
import { Theme } from '@mui/material';
import { merge } from 'lodash-es';
import { commonStyles } from 'src/styles';

const CONTENT_AREA_PADDING = 3;
export function getContentAreaStyles(theme: Theme) {
    return {
        footerRow: {
            paddingTop: theme.spacing(2),
        },
    };
}

export const styles = (theme: Theme) => {
    const baseStylesObj = {
        root: {
            height: '100%',
            minWidth: theme.spacing(50),
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.spacing(CONTENT_AREA_PADDING),
        },
        breadCrumbRoot: {
            ...fonts.breadcrumb,
            paddingTop: '0px',
            height: '16px',
            whiteSpace: 'pre',
        },
        breadcrumbSegment: {
            maxWidth: `${theme.spacing(40)} !important`,
        },
        headerSpacing: {
            minHeight: theme.spacing(4),
        },
        headerPadding: {
            paddingBottom: theme.spacing(),
        },
        contentRow: {
            ...commonStyles(theme).rowGrow,
            flexBasis: 'auto',
        },
        backgroundColor: {
            border: '0',
            boxShadow: 'none !important',
        },
        content: {
            width: '100%',
            minWidth: 0,
        },
        contentPadding: {
            padding: theme.spacing(2),
        },
        footerRow: {
            minHeight: theme.spacing(4),
            padding: '0px !important',
        },
        emptyBreadcrumbRow: {
            minHeight: '16px',
        },
        emptyTitleRow: {
            maxHeight: '8px',
            minHeight: '8px',
        },
    };

    const extraStylesObject = getContentAreaStyles(theme);

    const stylesObj = merge(baseStylesObj, extraStylesObject);

    return stylesObj;
};
