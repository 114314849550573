import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { importCollarUrl } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const importCollars = (payload: any): Observable<any> => {
    const url = importCollarUrl();
    return sendRequestWithXSRFToken(url, convertToSnake(payload)).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const result = convertToCamel(response);
            return observableOf(result);
        }),
    );
};
