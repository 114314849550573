import { Grid, Typography } from '@mui/material';
import { EventsObject, EventType, subscriptionState, userState } from 'state-domains/domain';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generateEventMessage } from 'src/components/Events/Events/Events.utils';
import { formatDateToDisplay } from 'src/utilities/formatters';
import { TimeType } from 'src/utilities/types';
import { Comments, RepliesDropdown, ReplyButton } from 'src/components/Comments';
import { CommentFormWithMentionableUsers } from 'src/components/Comments/CommentForm';

import { EventHeader } from '../EventPostHeader';
import useEventReply from '../useEventReply';

import { useStyles } from './EventCard.styles';
import { useEventLinkedObjectDetails } from './useEventLinkedObjectData';

const EventTimeline = memo(({ event }: { event: EventsObject }) => {
    const { classes } = useStyles();

    return (
        <Grid item container sx={{ width: '120px', minWidth: '120px' }}>
            <Grid container direction="column" sx={{ height: '100%' }}>
                <Grid item sx={{ height: '15px', display: 'flex' }}>
                    <Grid item xs sx={{}} />
                    <Grid item className={classes.timelineLine} />
                </Grid>
                <Grid item sx={{ height: '25px', display: 'flex' }}>
                    <Grid item xs>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ textAlign: 'right', paddingRight: '10px' }}
                        >
                            {formatDateToDisplay(event.updatedAt, TimeType.SECONDS)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid item className={classes.pointerTop} />
                        <Grid item className={classes.pointerBottom} />
                    </Grid>
                </Grid>
                <Grid item xs sx={{ display: 'flex' }}>
                    <Grid item xs />
                    <Grid item className={classes.timelineLine} />
                </Grid>
            </Grid>
        </Grid>
    );
});

export const EventCard = ({
    event,
    commentedId,
    setCommentedId,
    customReply,
}: {
    event: EventsObject;
    commentedId: string;
    setCommentedId: Function;
    customReply?: Function;
}) => {
    const {
        selectors: { currentUserId: currentUserIdSelector },
    } = userState;
    const {
        selectors: { user },
    } = subscriptionState;
    const { handleAddEventReply, commentsOpen, setCommentsOpen, getCommentsData } = useEventReply(
        event.id,
        EventType.EVENT,
        customReply,
    );

    const { classes } = useStyles();
    const onReply = useCallback((textValue: string) => {
        handleAddEventReply(textValue);
    }, []);

    const currentUserId = useSelector(currentUserIdSelector);
    const creator = useSelector(user(event.createdBy));
    const { itemName, itemUrl } = useEventLinkedObjectDetails(event);

    const comments = useMemo(() => event.post?.replies ?? [], [event.post?.replies]);
    const commentsData = useMemo(() => getCommentsData(comments), [comments]);

    return (
        <Grid container>
            <EventTimeline event={event} />
            <Grid item xs container className={classes.eventCard} sx={{ minWidth: 0 }}>
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                >
                    <Grid item sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Typography
                            className={classes.eventTitle}
                            noWrap
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {generateEventMessage(event, currentUserId)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ReplyButton id={event.id} setCommentedId={setCommentedId} />
                    </Grid>
                </Grid>
                <Grid item container direction="row" alignItems="center" wrap="nowrap">
                    <EventHeader
                        userName={creator?.profile?.name ?? ''}
                        objectLinkData={{ name: itemName, url: itemUrl }}
                    />
                    <Grid item xs container justifyContent="flex-end">
                        <RepliesDropdown
                            commentsCount={comments.length}
                            isOpen={commentsOpen}
                            setOpen={setCommentsOpen}
                        />
                    </Grid>
                </Grid>
                <Grid container item>
                    <CommentFormWithMentionableUsers
                        event={event}
                        commentedId={commentedId}
                        setCommentedId={setCommentedId}
                        onReply={onReply}
                        isEvent
                    />
                </Grid>
                <Grid item flex={1}>
                    <Comments commentsData={commentsData} isOpen={commentsOpen} />
                </Grid>
            </Grid>
        </Grid>
    );
};
