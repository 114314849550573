import { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DialogWithHeader } from 'src/components/DialogWithHeader';
import { EventCard } from 'src/components/Home/EventCard';
import { PostCard } from 'src/components/Home/PostCard';
import { EventsObject, PostsObject } from 'state-domains/domain/events/types';
import { AsyncStateError } from 'state-domains/types';
import { getErrorFromState } from 'state-domains/domain/utils';
import {
    constructErrorMessage,
    ErrorComponent,
} from 'src/components/DialogWithHeader/DialogNotifications';

import { i18n } from './NotificationModal.i118n';

interface NotificationModalProps {
    data: Record<string, any>;
    title: string;
    onClose: () => void;
}

export const NotificationModal = ({ data, title, onClose }: NotificationModalProps) => {
    const MODAL_WIDTH = '600px';

    const [item, setItem] = useState(data);
    const [commentedId, setCommentedId] = useState('');
    const [errorValue, setErrorValue] = useState('');
    const [showProgressBar, setShowProgressBar] = useState(false);
    const intl = useIntl();

    useEffect(() => setItem(data), [data]);

    const customReply = useCallback(
        (value: boolean, event?: EventsObject, errorMessage?: AsyncStateError) => {
            setShowProgressBar(value);

            if (event) {
                setItem(event);
                setCommentedId('');
            }

            if (errorMessage) {
                const { error, i18nKey } = getErrorFromState(errorMessage);

                if (error) {
                    setErrorValue(constructErrorMessage(intl, error, i18nKey));
                }
            } else {
                setErrorValue('');
            }
        },
        [],
    );

    const isPost = item?.eventType === 'post';

    return (
        <DialogWithHeader
            open={!!item.id}
            title={title ? intl.formatMessage(i18n[title]) : ''}
            onClose={onClose}
            onApply={onClose}
            applyDisabled
            showFooter={false}
            isPending={showProgressBar}
            dialogWidth={MODAL_WIDTH}
        >
            <>
                {isPost ? (
                    <PostCard
                        key={item?.id}
                        post={item.post as PostsObject}
                        commentedId={commentedId}
                        setCommentedId={setCommentedId}
                        customReply={customReply}
                    />
                ) : (
                    <EventCard
                        key={item?.id}
                        event={item as EventsObject}
                        commentedId={commentedId}
                        setCommentedId={setCommentedId}
                        customReply={customReply}
                    />
                )}
                {errorValue && <ErrorComponent message={errorValue} />}
            </>
        </DialogWithHeader>
    );
};
