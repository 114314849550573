import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { EventNotificationTypes, eventsState, subscriptionState } from 'state-domains/domain';
import { setSessionId } from 'src/utilities';
import React from 'react';

export interface WebsocketMessage {
    event: string;
    notification_id: string;
    user_id: string;
}

const MAX_RECONNECT_ATTEMPTS = 10;
const RECONNECT_INTERVALS = 5000;

export const WebSocketManager = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { getState } = useStore();

    const {
        selectors: { account: accountSelector },
    } = subscriptionState;

    const {
        actions: { updateJob, completeJob, modifyEventPosts },
    } = eventsState;

    const eventMap: Record<string, ((data: WebsocketMessage) => void)[]> = React.useMemo(
        () => ({
            jobUpdated: [
                (data: WebsocketMessage) => {
                    updateJob(data.event)(dispatch);
                },
            ],
            jobCompleted: [
                (data: WebsocketMessage) => {
                    completeJob(data.event);
                },
            ],
        }),
        [],
    );

    const account = useSelector(accountSelector);

    const websocketUrl = React.useMemo(() => {
        if (account?.domain) {
            return `wss://${account.domain}/websocket`;
        }
        return null;
    }, [account]);

    useWebSocket(websocketUrl, {
        onOpen: setSessionId,
        shouldReconnect: () => true,
        reconnectAttempts: MAX_RECONNECT_ATTEMPTS,
        reconnectInterval: RECONNECT_INTERVALS,
        onMessage: (event: MessageEvent) => {
            try {
                const eventData = JSON.parse(event.data);

                if (Object.values(EventNotificationTypes).includes(eventData.notificationType)) {
                    modifyEventPosts(
                        eventData.notificationData.event,
                        eventData.notificationData.user_id,
                        eventData.notificationType,
                        location.pathname,
                    )(dispatch, getState);
                } else if (eventData.notificationType in eventMap) {
                    eventMap[eventData.notificationType].forEach(
                        (callback: (data: any) => void) => {
                            callback(eventData.notificationData);
                        },
                    );
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Failed to parse WebSocket message:', error);
            }
        },
    });
    return <></>;
};
