import { makeStyles } from 'tss-react/mui';
import { mainFontTypes } from 'src/styles';

const lineWidth = 1;

const createGradient = (direction: string) =>
    `linear-gradient(to ${direction},
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,0) calc(50% - 0.8px),
    #8E9095 50%,
    rgba(0,0,0,0) calc(50% + ${lineWidth}px),
    rgba(0,0,0,0) 100%)`;

export const styles = () =>
    ({
        basicText: {
            ...mainFontTypes,
            fontSize: '12px',
        },
        dimText: {
            ...mainFontTypes,
            fontSize: '12px',
            color: '#8E9095',
        },
        innerText: {
            fontSize: '14px',
            paddingLeft: 0,
        },
        pointerTop: {
            width: '7px',
            height: '50%',
            background: createGradient('top right'),
        },
        pointerBottom: {
            width: '7px',
            height: '50%',
            background: createGradient('top left'),
        },
        timelineLine: {
            width: '14px',
            background: createGradient('right'),
        },
        eventTitle: {
            fontSize: '15px',
            lineWeight: 400,
            margin: 0,
        },
        eventCard: {
            margin: '8px 16px',
            border: '1px solid #C8C8C8',
            padding: '8px 16px',
            borderRadius: '3px',
            borderColor: 'rgb(225, 230, 230)',
        },
        objectLink: {
            marginLeft: '4px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
