import { ListCellComponentProps, StringCell } from '@local/web-design-system';
import { Avatar, AvatarGroup as MUIAvatarGroup, Grid, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { subscriptionState } from 'state-domains/domain';
import { useSelector } from 'react-redux';

import { AvatarGroupProfile, AvatarGroupProps, AvatarWithTooltipProps } from './AvatarGroup.types';
import { getColor, useStyles } from './AvatarGroup.styles';

const MAX_AVATARS = 4;

const avatarStyles = {
    fontSize: 12,
    width: 30,
    height: 30,
    fontWeight: 500,
    border: '2px solid white',
    color: '#000000 !important',
};

export const AvatarGroupCell = ({ item, itemKey }: Readonly<ListCellComponentProps>) => (
    <AvatarGroup profiles={item[itemKey]} maxAvatarsLimit={MAX_AVATARS} />
);

export const AvatarCell = ({ item, itemKey }: Readonly<ListCellComponentProps>) => (
    <AvatarWithTooltip
        name={item?.[itemKey]?.name ?? ''}
        id={uuidv4()}
        initials={item?.[itemKey]?.initials ?? ''}
        color={item?.[itemKey]?.color ?? ''}
    />
);

export const CreatedByAvatarCell = React.memo((props: ListCellComponentProps) => {
    const {
        selectors: { users: usersSelector },
    } = subscriptionState;

    const users = useSelector(usersSelector);
    const getCreatedUser = React.useCallback((id: string) => users?.[id]?.profile ?? {}, [users]);

    return (
        <AvatarCell
            {...props}
            item={{
                ...props.item,
                profile: getCreatedUser(props.item.createdBy ?? props.item.userId),
            }}
        />
    );
});

export const CreatedByNameCell = React.memo((props: ListCellComponentProps) => {
    const {
        selectors: { users: usersSelector },
    } = subscriptionState;

    const users = useSelector(usersSelector);
    const getCreatedUser = React.useCallback((id: string) => users?.[id]?.profile ?? {}, [users]);

    return (
        <StringCell
            {...props}
            item={{
                ...props.item,
                userName: getCreatedUser(props.item.createdBy ?? props.item.userId)?.name ?? '',
            }}
        />
    );
});

export const AvatarWithTooltip = ({
    name,
    id,
    initials,
    color,
    showTooltip,
    activityGroups,
}: AvatarWithTooltipProps) => {
    const { classes } = getColor({ color });
    let tooltipMsg: React.ReactNode = '';
    if (showTooltip) {
        tooltipMsg = name;
        if (activityGroups) {
            tooltipMsg = (
                <div
                    style={{ whiteSpace: 'pre-line' }}
                >{`${tooltipMsg}\n${Object.values(activityGroups).join(', ')}`}</div>
            );
        }
    }
    return (
        <Tooltip title={tooltipMsg} key={id ?? uuidv4()}>
            <Avatar variant="circular" alt={name} className={classes.cellColor} sx={avatarStyles}>
                {initials}
            </Avatar>
        </Tooltip>
    );
};

export const AvatarGroup = (props: AvatarGroupProps) => {
    const { profiles, maxAvatarsLimit, justifyEnd } = props;
    const avatars = profiles.map((p: AvatarGroupProfile) => (
        <AvatarWithTooltip {...p} key={p.id ?? uuidv4()} showTooltip />
    ));
    const { classes } = useStyles();
    return (
        <Grid container justifyContent={justifyEnd ? 'flex-end' : 'flex-start'} width="inherit">
            <MUIAvatarGroup
                automation-id="avatargroup-element"
                max={maxAvatarsLimit}
                spacing="medium"
                className={classes.group}
                sx={{ '& .MuiAvatar-root': avatarStyles }}
            >
                {avatars}
            </MUIAvatarGroup>
        </Grid>
    );
};
