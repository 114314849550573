import { Notification, NotificationType, theme } from '@local/web-design-system';
import { Grid } from '@mui/material';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getErrorFromState } from 'state-domains/domain';
import { AsyncState, AsyncStateError, ShimState } from 'state-domains/types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';
import { i18n as commonErrors } from 'src/components/Errors/Errors.i18n';
import { commonStyles } from 'src/styles';
import React from 'react';

const useStyles = makeStyles()(() => ({
    border: {
        ...commonStyles(theme).notificationBorder,
    },
    notificationMessage: {
        ...commonStyles(theme).notificationMessage,
    },
    panel: {
        width: '100%',
    },
}));

export const DialogWarningNotification = ({ warningMessage }: { warningMessage: string }) => {
    const { classes } = useStyles();

    return (
        warningMessage && (
            <Grid item container padding={theme.spacing(0, 3, 2, 3)}>
                <Notification
                    message={warningMessage}
                    type={NotificationType.WARNING}
                    classes={{
                        root: classes.panel,
                        contentText: classes.notificationMessage,
                        border: classes.border,
                    }}
                />
            </Grid>
        )
    );
};

export const ErrorComponent = ({ message }: { message: string }) => {
    const { classes } = useStyles();

    return (
        <Grid item container padding={theme.spacing(0, 3, 2, 3)}>
            <Notification
                message={message}
                type={NotificationType.ERROR}
                classes={{
                    root: classes.panel,
                    contentText: classes.notificationMessage,
                    border: classes.border,
                }}
            />
        </Grid>
    );
};

export const constructErrorMessage = (intl: IntlShape, error: AsyncStateError, i18nKey: boolean) =>
    i18nKey && (error.message ?? '') in commonErrors
        ? intl.formatMessage(commonErrors[error.message ?? ''])
        : (error.message ?? '');

export function DialogErrorNotification(
    props: Readonly<{
        state: (state: Partial<ShimState>) => AsyncState;
        saveInitiated: boolean;
    }>,
) {
    const { state, saveInitiated } = props;
    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationData, setNotificationData] = React.useState<{
        message: string;
    }>({} as any);

    const intl = useIntl();

    const { error: errorFromState } = useSelector(state);
    const { error, i18nKey } = getErrorFromState(errorFromState ?? ({} as AsyncStateError));
    const isFailed = useSelector(isFailedSelector(state));
    const isComplete = useSelector(isCompleteSelector(state));
    const isPending = useSelector(isPendingSelector(state));

    React.useEffect(() => {
        if (isFailed && !!error) {
            setShowNotification(true);
            setNotificationData({
                message: constructErrorMessage(intl, error, i18nKey),
            });
        } else if (isComplete || isPending) {
            setShowNotification(false);
        }
    }, [isFailed, error, isComplete, isPending]);

    return (
        <>
            {showNotification && !saveInitiated && (
                <Grid item container padding={theme.spacing(0, 3, 2, 3)}>
                    <ErrorComponent {...notificationData} />
                </Grid>
            )}
        </>
    );
}
