import { Grid, Typography, Link, Tooltip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { theme } from '@local/web-design-system';

import { useStyles } from './EventPostHeader.styles';

interface ObjectLinkData {
    name: string;
    url: string;
    onClickOverride?: Function;
    tooltipOverride?: string;
    multiline?: boolean;
}

interface EventHeaderProps {
    userName: string;
    objectLinkData: ObjectLinkData;
    tooltipOverride?: string;
}

export const Seperator = () => (
    <Typography variant="body2" sx={{ padding: '0 6px', color: '#8E9095' }}>
        |
    </Typography>
);

export const ObjectLink = ({
    name,
    url,
    onClickOverride,
    tooltipOverride,
    multiline,
}: ObjectLinkData) => {
    const { classes } = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (onClickOverride) {
            event.preventDefault();
            onClickOverride();
        }
    };

    return (
        <Tooltip title={tooltipOverride ?? name}>
            <Link
                href={url}
                underline="none"
                className={classes.flexEllipsis}
                onClick={handleClick}
            >
                <LinkIcon fontSize="inherit" sx={{ paddingRight: theme.spacing(0.5) }} />
                <Typography
                    variant="body2"
                    noWrap
                    className={classes.linkText}
                    sx={{
                        textWrapMode: multiline ? 'wrap' : 'auto',
                        wordBreak: multiline ? 'break-word' : 'auto',
                    }}
                >
                    {name}
                </Typography>
            </Link>
        </Tooltip>
    );
};

export const EventHeader = ({
    userName,
    objectLinkData: { name, url },
    tooltipOverride,
}: EventHeaderProps) => {
    return (
        <Grid container alignItems="center" wrap="nowrap" overflow="hidden">
            <Grid item>
                <Typography variant="body2" noWrap>
                    {userName}
                </Typography>
            </Grid>
            {name && url && (
                <>
                    <Grid item>
                        <Seperator />
                    </Grid>
                    <Grid item display="flex" overflow="hidden">
                        <ObjectLink name={name} url={url} tooltipOverride={tooltipOverride} />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
