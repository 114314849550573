import IntervalTree from '@flatten-js/interval-tree';
import { EPSILON_MULTIPLIER, PARENT_SAMPLE } from 'state-domains/constants';

import { Interval, RowReference } from './types';
import { getSampleTypeFromRowReference } from './utils';

export const isValidNumber = (input: any) => {
    let value = input;
    if (typeof value === 'string') {
        value = value.trim();
    }
    return value !== undefined && value !== null && value !== '' && !isNaN(value);
};

export const isOverlapping = (first: Interval, second: Interval | undefined) => {
    if (isValidNumber(first.to?.value) && isValidNumber(second?.from?.value)) {
        return second?.from && first.to && second.from.value < first.to.value;
    }
    return false;
};

export const isDupe = (first: Interval, second: Interval | undefined) => {
    if (second?.parentSample) return false;

    if (
        isValidNumber(first.from?.value) &&
        isValidNumber(first.to?.value) &&
        isValidNumber(second?.from?.value) &&
        isValidNumber(second?.to?.value)
    ) {
        return (
            first.from &&
            second?.from &&
            first.to &&
            second?.to &&
            first.from.value === second.from.value &&
            first.to.value === second.to.value
        );
    }
    if (isValidNumber(first.depth?.value) && isValidNumber(second?.depth?.value)) {
        return first.depth && second?.depth && first.depth.value === second.depth.value;
    }
    return false;
};

export const checkInterval = (
    first: Interval | undefined,
    second: Interval | undefined,
    currentInterval: Interval,
    field: 'from' | 'to',
    allowDupes = false,
) => {
    if (
        first &&
        isOverlapping(first, second) &&
        !(allowDupes && isDupe(first, second)) &&
        currentInterval[field]
    ) {
        currentInterval[field].errors = ['Overlaps are not allowed in this table'];
    }
    return currentInterval;
};

export const validateDepthDupes = (
    currentInterval: Interval,
    prevInterval: Interval | undefined,
    nextInterval: Interval | undefined,
    allowDupes = false,
) => {
    if (allowDupes) {
        return currentInterval;
    }
    if (
        currentInterval.depth &&
        (isDupe(currentInterval, prevInterval) || isDupe(currentInterval, nextInterval))
    ) {
        currentInterval.depth.errors = ['Duplicate depths are not allowed'];
    }
    return currentInterval;
};

export const validateOverlaps = (
    currentInterval: Interval,
    prevInterval: Interval | undefined,
    nextInterval: Interval | undefined,
    allowDupes = false,
) => {
    const updatedCurrentInterval = checkInterval(
        prevInterval,
        currentInterval,
        currentInterval,
        'from',
        allowDupes,
    );
    return checkInterval(
        updatedCurrentInterval,
        nextInterval,
        updatedCurrentInterval,
        'to',
        allowDupes,
    );
};

const hasDifferentSampleType = (sampleType: any, item: any) => {
    return !!(sampleType && item.sampleType && sampleType !== item.sampleType);
};

const createErrorMessages = (
    fromResult: any,
    toResult: any,
    currentInterval: Interval,
    errorHeading: string,
) => {
    if (fromResult && currentInterval.from) {
        currentInterval.from.errors = [
            `${errorHeading} ${fromResult.from} - ${fromResult.to} is not allowed.`,
        ];
    }

    if (toResult && currentInterval.to) {
        currentInterval.to.errors = [
            `${errorHeading} ${toResult.from} - ${toResult.to} is not allowed.`,
        ];
    }
};

const fieldIntervalErrors = (
    currentInterval: Interval,
    sampleType: any,
    intervalErrors: any,
): Interval => {
    let fromResult = null;
    let toResult = null;

    for (const item of intervalErrors.overlap ?? []) {
        if (hasDifferentSampleType(sampleType, item)) continue;

        if (
            !fromResult &&
            item.from <= currentInterval.from?.value &&
            item.to > currentInterval.from?.value
        ) {
            fromResult = item;
        }

        if (
            !toResult &&
            item.from < currentInterval.to?.value &&
            item.to >= currentInterval.to?.value
        ) {
            toResult = item;
        }

        if (!!fromResult && !!toResult) break;
    }

    createErrorMessages(fromResult, toResult, currentInterval, 'Overlap');

    const fromFound = !!fromResult;
    const toFound = !!toResult;

    if (!fromFound || !toFound) {
        for (const item of intervalErrors.gap ?? []) {
            if (hasDifferentSampleType(sampleType, item)) continue;

            if (!fromResult && item.to === currentInterval.from?.value) {
                fromResult = item;
            }

            if (!toResult && item.from === currentInterval.to?.value) {
                toResult = item;
            }

            if (!!fromResult && !!toResult) break;
        }

        createErrorMessages(
            !fromFound ? fromResult : null,
            !toFound ? toResult : null,
            currentInterval,
            'Gap',
        );
    }

    return currentInterval;
};

const binarySearchNextGreaterInterval = (
    arr: { key: number[]; value: string }[],
    low: number,
    high: number,
    key: number,
): { key: number[]; value: string } => {
    if (low < high) {
        const mid = Math.floor((high + low) / 2);
        if (arr[mid].key[0] <= key) {
            return binarySearchNextGreaterInterval(arr, mid + 1, high, key);
        }
        return binarySearchNextGreaterInterval(arr, low, mid, key);
    }
    return arr[low];
};

const getNextGreaterFrom = (arr: any[], key: number, start: number) => {
    const n = arr.length;
    if (arr[n - 1].key[0] <= key) {
        return arr[n - 1];
    }
    return binarySearchNextGreaterInterval(arr, start, n - 1, key);
};

export const validateGapErrorsFast = (
    rows: Record<string, any>,
    rowReferencesIntervalTree: IntervalTree | null,
    isSample = false,
) => {
    if (!rowReferencesIntervalTree) {
        return;
    }
    const validIntervalsForSampleType = [];
    const delta = EPSILON_MULTIPLIER * Number.EPSILON;
    if (isSample) {
        // If gap validations are for samples table we only keep those intervals that belong to
        // same sample type. Other intervals are not used for gap computation
        for (const interval of rowReferencesIntervalTree.items) {
            if (interval.value in rows) {
                validIntervalsForSampleType.push(interval);
            }
        }
    }
    const intervals = isSample ? validIntervalsForSampleType : rowReferencesIntervalTree.items;
    let maxToTillNow = Number.MIN_SAFE_INTEGER;
    const gapErrorsStart = Date.now();
    for (let i = 0; i < intervals.length; i += 1) {
        const currentInterval = intervals[i].key as unknown as number[];
        const currentId = intervals[i].value;
        const currentRow = rows[currentId];
        const currentFrom = currentInterval[0] - delta;
        const currentTo = currentInterval[1] + delta;
        const prevTo =
            i > 0
                ? (intervals[i - 1].key as unknown as number[])[1] + delta
                : Number.MAX_SAFE_INTEGER;
        if (
            i > 0 &&
            currentFrom > prevTo &&
            currentFrom > maxToTillNow &&
            currentRow &&
            currentRow.from.errors.length === 0
        ) {
            currentRow.from.errors = [`Gap ${maxToTillNow} - ${currentFrom} is not allowed.`];
        }
        // Making use of the knowledge that the from is generated by adding delta = EPSILON_MULTIPLIER * Number.EPSILON
        // to the value and to is generated by subtracting delta = EPSILON_MULTIPLIER * Number.EPSILON from the value
        const nextHigher = getNextGreaterFrom(intervals, currentTo, i);
        const nextHigherFrom = nextHigher.key[0] - delta;
        if (nextHigherFrom > currentTo && currentRow && currentRow.to.errors.length === 0) {
            currentRow.to.errors = [`Gap ${currentTo} - ${nextHigherFrom} is not allowed.`];
        }
        maxToTillNow = Math.max(maxToTillNow, currentTo);
    }
    // eslint-disable-next-line no-console
    console.log('Gap Error Validation Completed', Date.now() - gapErrorsStart);
};

export const validateIntervalErrorsFast = (
    rows: Record<string, any>,
    rowReferencesIntervalTree: IntervalTree | null,
    allowDupes: boolean,
    isSample = false,
) => {
    const validationErrorsStart = Date.now();
    const delta = EPSILON_MULTIPLIER * Number.EPSILON;
    for (const id in rows) {
        const row = rows[id];
        if ((row.from?.errors?.length && row.to?.errors?.length) || row[PARENT_SAMPLE]?.value) {
            // Already in error or a child row. Move to next
            continue;
        }
        const fromValue = row.from?.value;
        const toValue = row.to?.value;
        if (
            fromValue !== null &&
            fromValue !== undefined &&
            toValue !== null &&
            toValue !== undefined &&
            fromValue <= toValue
        ) {
            const result =
                rowReferencesIntervalTree?.search([fromValue, toValue], (rId, interval) =>
                    rId !== id ? { id: rId, from: interval.low, to: interval.high } : null,
                ) ?? [];
            let min = Number.MAX_SAFE_INTEGER;
            let max = Number.MIN_SAFE_INTEGER;
            for (const overlap of result) {
                // Overlap errors are only generated if the row is part of data. Rows for a different
                // sample type will not be part of data when this is run for samples table. In case
                // of samples table rows will only include data for the same sample type
                if (overlap?.id in rows || (!isSample && overlap)) {
                    const overlapFrom = overlap.from - delta;
                    const overlapTo = overlap.to + delta;
                    if (allowDupes && fromValue === overlapFrom && toValue === overlapTo) {
                        // Exact same interval overlaps with the current one and the table allows
                        // duplicates. Errors should not be added
                        continue;
                    }
                    min = Math.min(overlap.from, min);
                    max = Math.max(overlap.to, max);
                }
            }
            if (min !== Number.MAX_SAFE_INTEGER) {
                if (fromValue >= min - delta && row.from.errors.length === 0) {
                    row.from.errors = [
                        `Overlap ${fromValue} - ${Math.min(toValue, max + delta)} is not allowed.`,
                    ];
                }
                if (toValue <= max + delta && row.to.errors.length === 0) {
                    row.to.errors = [
                        `Overlap ${Math.max(fromValue, min - delta)} - ${Math.min(
                            toValue,
                            max + delta,
                        )} is not allowed.`,
                    ];
                }
                if (fromValue <= min - delta && toValue >= max + delta) {
                    if (row.from.errors.length === 0) {
                        row.from.errors = [
                            `Overlap ${min - delta} - ${max + delta} is not allowed.`,
                        ];
                    }
                    if (row.to.errors.length === 0) {
                        row.to.errors = [`Overlap ${min - delta} - ${max + delta} is not allowed.`];
                    }
                }
            }
        }
    }
    // eslint-disable-next-line no-console
    console.log('Interval Error Validation Completed', Date.now() - validationErrorsStart);
};

export const validateDepthErrors = (
    currentInterval: Interval,
    rowReference: RowReference,
    intervalErrors: any,
): Interval => {
    if (
        currentInterval.depth &&
        intervalErrors.dupe &&
        intervalErrors.dupe.indexOf(currentInterval.depth.value) >= 0
    ) {
        currentInterval.depth.errors = [
            `Duplicate depth ${currentInterval.depth.value} is not allowed.`,
        ];
    }
    return currentInterval;
};

export const validateIntervalErrors = (
    currentInterval: Interval,
    rowReference: RowReference,
    isInterval: boolean,
    intervalErrors: any,
): Interval => {
    let result = currentInterval;
    if (isInterval) {
        const sampleType = getSampleTypeFromRowReference(rowReference);
        result = fieldIntervalErrors(currentInterval, sampleType, intervalErrors);
    }
    return result;
};

export const validateGaps = (currentInterval: Interval, prevInterval: Interval | undefined) => {
    if (
        currentInterval.from &&
        currentInterval.to &&
        prevInterval?.to &&
        isValidNumber(currentInterval.from.value) &&
        isValidNumber(prevInterval.to.value) &&
        currentInterval.from.value > prevInterval.to.value
    ) {
        currentInterval.from.errors = ['Gaps are not allowed in this table'];

        // currentInterval.to.errors = ['Gaps are not allowed in this table'];
    }
    return currentInterval;
};

export const validateOrder = (
    currentInterval: Interval,
    prevInterval: Interval | undefined,
    nextInterval: Interval | undefined,
) => {
    if (
        currentInterval.from &&
        isValidNumber(currentInterval.from.value) &&
        currentInterval.to &&
        isValidNumber(currentInterval.to.value) &&
        ((prevInterval?.from &&
            isValidNumber(prevInterval.from.value) &&
            currentInterval.from.value < prevInterval.from.value) ||
            (nextInterval?.from &&
                isValidNumber(nextInterval.from.value) &&
                !nextInterval?.parentSample &&
                nextInterval.from.value < currentInterval.from.value))
    ) {
        currentInterval.from.errors = ['Interval out of order'];

        currentInterval.to.errors = ['Interval out of order'];
    }

    if (
        currentInterval.depth &&
        isValidNumber(currentInterval.depth.value) &&
        ((prevInterval?.depth &&
            isValidNumber(prevInterval.depth.value) &&
            prevInterval.depth.value > currentInterval.depth.value) ||
            (nextInterval?.depth &&
                isValidNumber(nextInterval.depth.value) &&
                !nextInterval?.parentSample &&
                currentInterval.depth.value > nextInterval.depth.value))
    ) {
        currentInterval.depth.errors = ['Depth out of order'];
    }

    return currentInterval;
};

export const createValuesWithValidationErrors = (
    modifiedItems: Record<string, any>,
    isInterval: boolean,
): Interval => {
    Object.entries(modifiedItems).forEach(([key, data]) => {
        const { value } = data;
        const keyString = key.charAt(0).toUpperCase() + key.slice(1);

        if (value === undefined || value === null) {
            modifiedItems[key].errors = [`${keyString} is required.`];
        } else if (isNaN(value) || !isValidNumber(value)) {
            modifiedItems[key].errors = [`${keyString} must be numeric.`];

            modifiedItems[key].value = undefined;
        } else {
            modifiedItems[key].value *= 1;
        }
    });

    if (isInterval) {
        const fromValue = modifiedItems.from?.value;
        const toValue = modifiedItems.to?.value;

        if (isValidNumber(fromValue) && isValidNumber(toValue) && fromValue >= toValue) {
            modifiedItems.from.errors = ['From must be less than To.'];

            modifiedItems.to.errors = ['To must be greater than From.'];
        }
    }

    return modifiedItems;
};
