import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { DEFAULT_LAB_SERVICE_KEY_MAP, LAB_SERVICES_LIST_ID } from 'state-domains/constants';

import { buildLoadDefaultLabServicesUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import {
    DefaultLabService,
    DefaultLabServiceCollections,
    DefaultLabServicesResponse,
} from '../types';

export const loadDefaultLabServices = (): Observable<DefaultLabServiceCollections> => {
    const url = buildLoadDefaultLabServicesUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const { filtered_lab_services } = response;

            // If more default import values are added in the future we will categorize them here.
            const defaultLabServices: DefaultLabServiceCollections = Object.values(
                filtered_lab_services as DefaultLabServicesResponse,
            ).reduce(
                (accum, value) => {
                    Object.values(value).forEach((x) =>
                        Object.values(x).forEach((y) =>
                            y.forEach((z: any) => {
                                const labService = convertToCamel<DefaultLabService>(z);

                                // Convert the plain text keys into lab service column keys.
                                const convertedLabService = Object.entries(labService).reduce(
                                    (accum, [key, value]) => {
                                        accum[
                                            (key in DEFAULT_LAB_SERVICE_KEY_MAP
                                                ? DEFAULT_LAB_SERVICE_KEY_MAP[key]
                                                : key) as keyof DefaultLabService
                                        ] = value;

                                        return accum;
                                    },
                                    {} as DefaultLabService,
                                );

                                accum[LAB_SERVICES_LIST_ID][labService.id] = convertedLabService;
                            }),
                        ),
                    );

                    return accum;
                },
                { [LAB_SERVICES_LIST_ID]: {} },
            );

            return observableOf(defaultLabServices);
        }),
    );
};
