import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildValidationRulesUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken, convertToSnake } from '../../../utils';
import { ValidationRule } from '../types';

export const addConfigurationValidationRule = (
    payload: Partial<ValidationRule>,
): Observable<ValidationRule> => {
    const url = buildValidationRulesUrl();
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ValidationRule>) => {
            const result = convertToCamel<ValidationRule>(response);
            return observableOf(result);
        }),
    );
};
