import { Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions';
import { common, theme } from '@local/web-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { subscriptionState } from 'state-domains/domain';
import { useSelector } from 'react-redux';

export interface MentionableUser {
    id: string;
    name: string;
}
interface MentionsTextFieldProps {
    placeholder: string;
    onChange: OnChangeHandlerFunc;
    value: string;
    mentionableUsers: MentionableUser[];
    setMentionedUsers?: Function;
}

export const MentionsTextField = (props: MentionsTextFieldProps) => {
    const { placeholder, onChange, value, setMentionedUsers, mentionableUsers } = props;

    const {
        selectors: { users: usersSelector },
    } = subscriptionState;

    const [addedUsers, setAddedUsers] = useState<any[]>([]);
    const users = useSelector(usersSelector);

    const userOptions = useMemo(
        () =>
            mentionableUsers
                .filter((user) => !addedUsers.includes(user.id))
                .sort((a: MentionableUser, b: MentionableUser) =>
                    (a.name ?? '').localeCompare(b.name ?? ''),
                )
                .map((user) => ({
                    id: user.id,
                    display: user.name,
                })),
        [mentionableUsers, addedUsers],
    );

    const transformMentionToString = useCallback(
        // Include users who have been mentioned but do not have access permissions by searching through all users
        (id: string, _display: string) => users[id].profile.name ?? '',
        [mentionableUsers],
    );

    const onChangeFn: OnChangeHandlerFunc = useCallback(
        (event, newValue, newPlainTextValue, mentions) => {
            const mentionedUsers = [...mentions.map((x) => x.id)];

            setAddedUsers(mentionedUsers);
            setMentionedUsers?.(mentionedUsers);
            onChange(event, newValue, newPlainTextValue, mentions);
        },
        [onChange],
    );

    useEffect(() => {
        const textField = document.querySelector('#mention-text-field') as HTMLElement;
        if (textField) {
            textField.focus();
        }
    }, []);

    return (
        <MentionsInput
            id="mention-text-field"
            value={value}
            onChange={onChangeFn}
            placeholder={placeholder}
            style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                letterSpacing: '0.1px',
                backgroundColor: theme.palette.grey[100],
                '&multiLine': {
                    control: {
                        minHeight: '100px',
                        fontSize: '13px',
                    },
                    highlighter: {
                        padding: 16,
                    },
                    input: {
                        padding: 16,
                        border: '1px solid silver',
                        outline: 'none',
                        overflowY: 'auto',
                    },
                },
                suggestions: {
                    list: {
                        backgroundColor: 'white',
                        border: '1px solid rgba(0,0,0,0.15)',
                        fontSize: 14,
                        maxHeight: '200px',
                        ...common(theme).thinScrollBar,
                    },
                    item: {
                        padding: '5px 15px',
                        borderBottom: '1px solid rgba(0,0,0,0.15)',
                        '&focused': {
                            backgroundColor: '#B2D4F4',
                        },
                    },
                },
                wordBreak: 'break-word',
            }}
        >
            <Mention
                trigger="@"
                appendSpaceOnAdd
                data={userOptions}
                displayTransform={transformMentionToString}
                markup={`<span class="taggedPerson">@__id__@</span>&nbsp;`}
                style={{
                    backgroundColor: '#B2D4F4',
                }}
            />
        </MentionsInput>
    );
};
