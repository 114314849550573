import { FileObj, PostsObject, UserWithType } from 'state-domains/domain';
import { Dispatch } from '@reduxjs/toolkit';
import { Slide } from 'yet-another-react-lightbox';

export const getPostUsers = (
    post: PostsObject,
    users: Record<string, UserWithType>,
    mentionableUsers: { id: string; name: string }[],
) => {
    const availableMentions = mentionableUsers.filter((x) =>
        post.shareWith?.length ? post?.shareWith?.includes(x.id) : true,
    );
    return { availableMentions };
};

export const downloadFileFromPostAttachment =
    (attachments: FileObj[], post: PostsObject, downloadFunction: Function, dispatch: Dispatch) =>
    ({ slide }: { slide: Slide; saveAs: (source: string | Blob, name?: string) => void }) => {
        const file = attachments.find((x) => slide.src.includes(x.file));
        if (file) {
            downloadFunction(post.id, file.id, file.originalFilename)(dispatch);
        }
    };
