import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import {
    buildLoadDrillholeCountUrl,
    buildLoadDrillholesUrl,
    buildLoadPointsCountUrl,
    buildLoadPointsUrl,
} from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRetrieveRequest } from '../../../utils';
import {
    Drillhole,
    DrillholeCountResponse,
    FilterObject,
    DrillholeResponse,
    LoadDrillholesParameters,
} from '../types';

export const loadDrillholeCount = (
    projectId: string,
    type: ACTIVITY_TYPES,
    activities: string[],
    state: string[],
    filters: FilterObject[],
): Observable<DrillholeCountResponse> => {
    const url =
        [ACTIVITY_TYPES.DRILLING, ACTIVITY_TYPES.TEMP_DRILLING].includes(type)
            ? buildLoadDrillholeCountUrl(projectId, activities, state, filters)
            : buildLoadPointsCountUrl(projectId, activities, state, filters);
    return sendRetrieveRequest(url).pipe(switchMap(({ response }) => observableOf(response)));
};

export const loadDrillholes = (
    projectId: string,
    type: ACTIVITY_TYPES,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    params: LoadDrillholesParameters,
): Observable<Drillhole[]> => {
    const { sortKey, offset = 0, limit = 20 } = params;
    const url =
        [ACTIVITY_TYPES.DRILLING, ACTIVITY_TYPES.TEMP_DRILLING].includes(type)
            ? buildLoadDrillholesUrl(projectId, activities, state, filters, sortKey, offset, limit)
            : buildLoadPointsUrl(projectId, activities, state, filters, sortKey, offset, limit);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<DrillholeResponse>) => {
            const { data } = response;
            const result = convertToCamelWithSpecialParameters<Drillhole[]>(data, {
                id: 'uid',
            });
            return observableOf(result);
        }),
    );
};
