import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildPostsUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { CLIENT_SIDE_PAGINATION_LIMIT, POSTS_DEFAULT_SORT_OPTION } from 'state-domains/constants';

import { sendRetrieveRequest } from '../../../utils';
import { PostsObject } from '../types';

export const loadPosts = (
    filters: FilterObject[],
    limit = CLIENT_SIDE_PAGINATION_LIMIT,
    offset = 0,
    sort = POSTS_DEFAULT_SORT_OPTION,
): Observable<{ count: number; Post: PostsObject[] }> => {
    const obs = sendRetrieveRequest(buildPostsUrlWithFilters(filters, limit, offset, sort));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<{ count: number; Post: PostsObject[] }>) => {
            return observableOf(convertToCamel<{ count: number; Post: PostsObject[] }>(response));
        }),
    );
};
