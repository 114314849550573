import { NotificationType } from '@local/web-design-system';
import { buildMapsTokenUrl } from 'state-domains/network';
import { ADD_SNACKBAR_NOTIFICATION } from 'state-domains/types/actionTypes';
import { ApiClient } from 'state-domains/utils/network/service/ApiClient';

import { mapState } from '../state';
import { AzureMapToken } from '../types';

import { APIMapToken } from './types';

export const AzureMapTokenApi = ApiClient.injectEndpoints({
    endpoints: (build) => ({
        loadAzureMapToken: build.query<AzureMapToken, undefined>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                const res = await baseQuery(buildMapsTokenUrl());
                if (res.error) {
                    return { error: res.error };
                }
                const data = res.data as APIMapToken;
                const token: AzureMapToken = {
                    expiry: Date.now() + data.expires_in * 1000,
                    tokenType: data.token_type,
                    accessToken: data.access_token,
                    azureClientId: data.azure_client_id,
                };
                return { data: token };
            },
            async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
                const { loadTokenComplete, loadMapTokenFail, loadTokenPending } = mapState.actions;
                dispatch(loadTokenPending());
                try {
                    const { data } = await queryFulfilled;
                    dispatch(loadTokenComplete(data));
                } catch (err: any) {
                    dispatch(loadMapTokenFail(err));
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            i18nKey: 'mapTokenFailed',
                            type: NotificationType.ERROR,
                            message: {
                                response: err?.error?.data,
                                message: err?.error?.error,
                                status: err?.error.originalStatus,
                            },
                        },
                    });
                }
            },
        }),
    }),
});

export const { useLoadAzureMapTokenQuery } = AzureMapTokenApi;
