import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { EventsObject } from 'state-domains/domain';

import { postReplyUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addEventReply = (payload: {
    event: string;
    reply: string;
}): Observable<{ event: EventsObject }> => {
    return sendRequestWithXSRFToken(postReplyUrl, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<{ event: EventsObject }>) => {
            const updatedEvent = convertToCamel<{ event: EventsObject }>(response);
            return observableOf(updatedEvent);
        }),
    );
};
