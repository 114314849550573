import { EventsObject } from 'state-domains/domain';

export enum EVENT_TYPES {
    DRILLHOLE_INSERTED = 'drillhole_inserted',
    POINT_INSERTED = 'point_inserted',
    DRILLHOLE_CLONED = 'drillhole_cloned',
    POINT_CLONED = 'point_cloned',
    DRILLHOLE_DELETED = 'drillhole_deleted',
    POINT_DELETED = 'point_deleted',
    SAMPLE_DISPATCH_INSERTED = 'sample_dispatch_inserted',
    SAMPLE_DISPATCH_DELETED = 'sample_dispatch_deleted',
    LAB_CERTIFICATE_INSERTED = 'lab_certificate_inserted',
    LAB_CERTIFICATE_IMPORTED = 'lab_certificate_imported',
    LAB_CERTIFICATE_RESULTS_RELEASED = 'lab_certificate_results_released',
    LAB_CERTIFICATE_RESULTS_UNRELEASED = 'lab_certificate_results_unreleased',
    DRILLHOLE_COMMENT_INSERTED = 'drillhole_comment_inserted',
    PROJECT_USER_ADDED = 'project_user_added',
    PROJECT_USER_REMOVED = 'project_user_removed',
    DRILLHOLE_USER_ASSIGNED = 'drillhole_user_assigned',
    DRILLHOLE_USER_UNASSIGNED = 'drillhole_user_unassigned',
    POINT_USER_ASSIGNED = 'point_user_assigned',
    POINT_USER_UNASSIGNED = 'point_user_unassigned',
    DRILLHOLE_STATUS_CHANGED = 'drillhole_status_changed',
    POINT_STATUS_CHANGED = 'point_status_changed',
    DRILLHOLE_STATE_CHANGED = 'drillhole_state_changed',
    POINT_STATE_CHANGED = 'point_state_changed',
    SAMPLE_DISPATCH_STATUS_CHANGED = 'sample_dispatch_status_changed',
    APP_USER_ADDED = 'app_user_added',
    ACTIVITY_DRILLHOLES_IMPORTED = 'activity_drillholes_imported',
    ACTIVITY_POINTS_IMPORTED = 'activity_points_imported',
    POST = 'post',
    EXPORT_TEMPLATE_CREATED = 'export_template_created',
    EXPORT_TEMPLATE_DELETED = 'export_template_deleted',
    JOB_COMPLETED = 'job_completed',
    ACTIVITY_USER_ADDED = 'activity_user_added',
    ACTIVITY_USER_REMOVED = 'activity_user_removed',
}

export enum EVENT_TYPE_DETAILS {
    EXPORT = 'Export',
    IMPORT = 'Import',
    LIST_EXPORT = 'List-Export',
    RANKED_COLUMN_RECOMPUTE = 'Ranked-Column-Recompute',
    RECALCULATION = 'Recalculation',
    TABLE_EXPORT = 'Table-Export',
}

const getUser = (event: EventsObject, currentUser: string) => {
    if (event.linkedId === currentUser) {
        if (event.updatedBy === currentUser) {
            return 'yourself'; // You added YOURSELF to THING
        }
        return 'you'; // Bob added YOU to THING
    }

    return event.linkedName; // You added BOB to THING, Bob added BRIAN to THING
};

const eventMessages = {
    [EVENT_TYPES.DRILLHOLE_INSERTED]: (event: EventsObject) =>
        `Created drill hole ${event.referenceName}`,
    [EVENT_TYPES.POINT_INSERTED]: (event: EventsObject) => `Created point ${event.referenceName}`,
    [EVENT_TYPES.DRILLHOLE_CLONED]: (event: EventsObject) =>
        `Cloned drill hole ${event.referenceName} from ${event.detail}`,
    [EVENT_TYPES.POINT_CLONED]: (event: EventsObject) =>
        `Cloned point ${event.referenceName} from ${event.detail}`,
    [EVENT_TYPES.DRILLHOLE_DELETED]: (event: EventsObject) =>
        `Deleted drill hole ${event.referenceName}`,
    [EVENT_TYPES.POINT_DELETED]: (event: EventsObject) => `Deleted point ${event.referenceName}`,
    [EVENT_TYPES.SAMPLE_DISPATCH_INSERTED]: (event: EventsObject) =>
        `Created sample dispatch ${event.referenceName}`,
    [EVENT_TYPES.SAMPLE_DISPATCH_DELETED]: (event: EventsObject) =>
        `Deleted sample dispatch ${event.referenceName}`,
    [EVENT_TYPES.LAB_CERTIFICATE_INSERTED]: (event: EventsObject) =>
        `Created lab certificate ${event.referenceName}`,
    [EVENT_TYPES.LAB_CERTIFICATE_IMPORTED]: (event: EventsObject) =>
        `Imported certificate ${event.referenceName}`,
    [EVENT_TYPES.LAB_CERTIFICATE_RESULTS_RELEASED]: (event: EventsObject) =>
        `Released results for certificate ${event.referenceName}`,
    [EVENT_TYPES.LAB_CERTIFICATE_RESULTS_UNRELEASED]: (event: EventsObject) =>
        `Unreleased results for certificate ${event.referenceName}`,
    [EVENT_TYPES.DRILLHOLE_COMMENT_INSERTED]: (event: EventsObject) =>
        `Added a comment to drill hole ${event.referenceName}`,
    [EVENT_TYPES.PROJECT_USER_ADDED]: (event: EventsObject, currentUser: string) =>
        `Added ${getUser(event, currentUser)} to ${event.referenceName}`,
    [EVENT_TYPES.ACTIVITY_USER_ADDED]: (event: EventsObject, currentUser: string) =>
        `Added ${getUser(event, currentUser)} to ${event.referenceName}`,
    [EVENT_TYPES.PROJECT_USER_REMOVED]: (event: EventsObject, currentUser: string) =>
        `Removed ${getUser(event, currentUser)} from ${event?.referenceName ?? ''}`,
    [EVENT_TYPES.ACTIVITY_USER_REMOVED]: (event: EventsObject, currentUser: string) =>
        `Removed ${getUser(event, currentUser)} from ${event?.referenceName ?? ''}`,
    [EVENT_TYPES.DRILLHOLE_USER_ASSIGNED]: (event: EventsObject, currentUser: string) =>
        `Assigned ${getUser(event, currentUser)} to ${event.referenceName}`,
    [EVENT_TYPES.DRILLHOLE_USER_UNASSIGNED]: (event: EventsObject, currentUser: string) =>
        `Un-assigned ${getUser(event, currentUser)} from ${event.referenceName}`,
    [EVENT_TYPES.POINT_USER_ASSIGNED]: (event: EventsObject, currentUser: string) =>
        `Assigned ${getUser(event, currentUser)} to ${event.referenceName}`,
    [EVENT_TYPES.POINT_USER_UNASSIGNED]: (event: EventsObject, currentUser: string) =>
        `Un-assigned ${getUser(event, currentUser)} from ${event.referenceName}`,
    [EVENT_TYPES.DRILLHOLE_STATUS_CHANGED]: (event: EventsObject) =>
        `Changed drill hole ${event.referenceName} status to ${event.detail === 'inprogress' ? 'in progress' : event.detail}`,
    [EVENT_TYPES.POINT_STATUS_CHANGED]: (event: EventsObject) =>
        `Changed point ${event.referenceName} status to ${event.detail === 'inprogress' ? 'in progress' : event.detail}`,
    [EVENT_TYPES.DRILLHOLE_STATE_CHANGED]: (event: EventsObject) =>
        `Changed drill hole ${event.referenceName} state from ${event.detail} to ${event.detail1}`,
    [EVENT_TYPES.POINT_STATE_CHANGED]: (event: EventsObject) =>
        `Changed point ${event.referenceName} state from ${event.detail} to ${event.detail1}`,
    [EVENT_TYPES.SAMPLE_DISPATCH_STATUS_CHANGED]: (event: EventsObject) =>
        `Changed sample dispatch ${event.referenceName} status to ${event.detail}`,
    [EVENT_TYPES.APP_USER_ADDED]: (event: EventsObject, currentUser: string) =>
        `Added ${getUser(event, currentUser)} to MX Deposit`,
    [EVENT_TYPES.ACTIVITY_DRILLHOLES_IMPORTED]: (event: EventsObject) =>
        `Imported drill holes into activity ${event.referenceName}`,
    [EVENT_TYPES.ACTIVITY_POINTS_IMPORTED]: (event: EventsObject) =>
        `Imported points into activity ${event.referenceName}`,
    [EVENT_TYPES.POST]: (event: EventsObject) => {
        const attCount = Object.values(event.post?.attachments ?? {}).length;
        const fileString = attCount == 1 ? ' file' : ' files';
        return `Added ${!event.post.content && attCount > 0 ? attCount + fileString : 'a comment'} ${event.referenceDetail ? 'to ' + event.referenceDetail : ''}`;
    },
    [EVENT_TYPES.EXPORT_TEMPLATE_CREATED]: (event: EventsObject) =>
        `Created new export template ${event.referenceName}`,
    [EVENT_TYPES.EXPORT_TEMPLATE_DELETED]: (event: EventsObject) =>
        `Deleted export template ${event.referenceName}`,
    [EVENT_TYPES.JOB_COMPLETED]: (event: EventsObject) => {
        switch (event.detail) {
            case EVENT_TYPE_DETAILS.EXPORT:
                return `Data export finished (${event.referenceName})`;
            case EVENT_TYPE_DETAILS.IMPORT:
                return `Data import finished (${event.referenceName})`;
            case EVENT_TYPE_DETAILS.LIST_EXPORT:
                return `List export finished (${event.referenceName})`;
            case EVENT_TYPE_DETAILS.RANKED_COLUMN_RECOMPUTE:
                return `Ranked column recompute finished (${event.referenceName})`;
            case EVENT_TYPE_DETAILS.RECALCULATION:
                return `Data recalculation finished (${event.referenceName})`;
            case EVENT_TYPE_DETAILS.TABLE_EXPORT:
                return `Table export finished (${event.referenceName})`;
            default:
                return '';
        }
    },
};

export const generateEventMessage = (event: EventsObject, currentUser: string) => {
    const messageGenerator = eventMessages[event.eventType as unknown as EVENT_TYPES];
    return messageGenerator ? messageGenerator(event, currentUser) : '';
};
