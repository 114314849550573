import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AttachFileToPostResponse } from 'state-domains/domain';

import { buildPostAttachmentsUrl } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const attachFileToPost = (
    postId: string,
    payload: File,
): Observable<{ file: AttachFileToPostResponse; name: string }> => {
    return sendFileUploadRequestWithXSRFTokenCatchErrors(buildPostAttachmentsUrl(postId), {
        file: payload,
    }).pipe(
        switchMap((result: any) => {
            const converted = !('_id' in result) ? result : convertToCamel(result);
            return observableOf({ file: converted, name: payload.name });
        }),
    );
};
