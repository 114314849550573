import { of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { Drillhole, RowReference, RowReferenceResponse } from 'state-domains/domain';
import { convertToCamelWithSpecialParameters, sendRequestWithXSRFToken } from 'state-domains/utils';

export function getRowReferenceResponseObservable(url: string, payload: any) {
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<RowReferenceResponse>) => {
            const { RowReference: RowReferenceResult, Collar } = response;
            const convertedReferences = convertToCamelWithSpecialParameters<RowReference[]>(
                RowReferenceResult,
                { id: 'uid' },
            );
            const convertedCollar = convertToCamelWithSpecialParameters<Drillhole>(Collar, {
                id: 'uid',
                _id: 'id',
            });
            return observableOf({
                RowReference: convertedReferences,
                Collar: convertedCollar,
            } as RowReferenceResponse);
        }),
    );
}

export function getRowReferenceWithNextRowsResponseObservable(
    url: string,
    rowReference: any,
    method: 'POST' | 'PUT',
) {
    return sendRequestWithXSRFToken(url, rowReference, method).pipe(
        switchMap(({ response }: AjaxResponse<RowReferenceResponse>) => {
            const { RowReference, Collar } = response;
            const convertedReference = convertToCamelWithSpecialParameters<RowReference>(
                RowReference,
                { id: 'uid' },
            );
            const convertedCollar = convertToCamelWithSpecialParameters<Drillhole>(Collar, {
                id: 'uid',
                _id: 'id',
            });

            const convertedNextRows = convertToCamelWithSpecialParameters<
                Record<string, RowReference>
            >(response.NextRows ?? {}, { id: 'uid' });
            return observableOf({
                RowReference: [convertedReference],
                Collar: convertedCollar,
                NextRows: convertedNextRows,
            } as RowReferenceResponse);
        }),
    );
}
