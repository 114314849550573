import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { buildNotifyUrl } from 'state-domains/network/urls';

import { sendRequestWithXSRFToken } from '../../../utils';

export const notify = (payload: { id: string; type: string }): Observable<any> => {
    return sendRequestWithXSRFToken(buildNotifyUrl(), payload).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const converted = convertToCamel<any>(response);
            return observableOf(converted);
        }),
    );
};
