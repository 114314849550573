import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { evoWorkspaceUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { WorkspaceEvoState } from '../types';

export const loadEvoWorkspaces = (): Observable<WorkspaceEvoState[]> => {
    const obs = sendRetrieveRequest(evoWorkspaceUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const workspaces = convertToCamel<any>(response.Workspaces);
            return observableOf(workspaces);
        }),
    );
};
