import { makeStyles } from 'tss-react/mui';

export const getColor = makeStyles<{ color: string }>()((theme, { color }) => {
    const value = color || theme.palette.secondary.main;
    return {
        cellColor: {
            color: '#000000',
            backgroundColor: value,
        },
    };
});

export const useStyles = makeStyles()(
    () =>
        ({
            group: {
                display: 'flex',
                alignItems: 'flex-start',
                color: '#000000',
                marginLeft: '-4px',
                paddingLeft: '0px',
                flexWrap: 'wrap',
            },
        }) as const,
);
