import { loadJobList } from './loadJobList';
import { loadEvents } from './loadEvents';
import { loadPosts } from './loadPosts';
import { notify } from './notify';
import { deletePosts } from './deletePosts';
import { downloadPostAttachment } from './downloadPostAttachment';
import { addEventReply } from './addEventReply';
import { loadLinkedObjects } from './loadLinkedObjects';
import { createPost } from './createPost';
import { attachFileToPost } from './attachFileToPost';
import { updatePost } from './updatePost';
import { updateJob } from './updateJob';

export const eventsShim = {
    loadJobList,
    loadEvents,
    loadPosts,
    notify,
    createPost,
    attachFileToPost,
    deletePosts,
    downloadPostAttachment,
    addEventReply,
    loadLinkedObjects,
    updatePost,
    updateJob,
};
