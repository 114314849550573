import { useState, useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import { EventType, eventsState, PostReply, subscriptionState } from 'state-domains/domain';

const useEventReply = (id: string, type: EventType, customReply?: Function) => {
    const {
        actions: { addEventReply },
    } = eventsState;
    const {
        selectors: { users: usersSelector },
    } = subscriptionState;

    const { getState } = useStore();
    const dispatch = useDispatch();
    const users = useSelector(usersSelector);

    const [commentsOpen, setCommentsOpen] = useState(false);

    const getCommentsData = useCallback(
        (comments: PostReply[]) =>
            comments.map((comment) => {
                const user = users[comment.userId];
                return {
                    user,
                    name: user?.profile.name,
                    content: comment.content,
                    date:
                        comment.postedDatetime?.date &&
                        formatDistanceToNow(new Date(comment.postedDatetime.date), {
                            addSuffix: true,
                        }),
                    dateTime: comment.postedDatetime?.date,
                };
            }),
        [],
    );

    const handleAddEventReply = useCallback(
        (textValue: string) => {
            addEventReply(id, textValue.trim(), type, customReply)(dispatch, getState);
        },
        [id, type, customReply, location],
    );

    return {
        handleAddEventReply,
        getCommentsData,
        commentsOpen,
        setCommentsOpen,
    };
};

export default useEventReply;
