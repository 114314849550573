import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildSortRowReferencesUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const sortRowReferences = (payload: any): Observable<Record<string, number>> => {
    const url = buildSortRowReferencesUrl();
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<{ RowReferenceIndexes: Record<string, number> }>) => {
            const { RowReferenceIndexes } = response;
            return observableOf(RowReferenceIndexes);
        }),
    );
};
