import { IMAGO_CHECK_CONNECTION } from 'state-domains/types/actionTypes';

export const addImagoResultToStateCollection = (
    actionName: string,
    result: any,
    imagoIntegrationState: { connectionStatus: string },
) => {
    if (actionName === IMAGO_CHECK_CONNECTION) {
        imagoIntegrationState.connectionStatus = result;
    }
};
