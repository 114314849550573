import { format } from 'date-fns';
import { MXDTimestamp } from 'state-domains/domain';

import { TimeType } from './types';

const DEFAULT_TRUNCATE_LENGTH = 20;
export function truncateInputString(input: string, truncate = DEFAULT_TRUNCATE_LENGTH) {
    return input.length > truncate ? `${input.substring(0, truncate)}...` : input;
}

export const generateObjectId =
    () =>
    (m = Math, d = Date, h = 16, s = (s: any) => m.floor(s).toString(h)) =>
        s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

const DATE_DISPLAY_FORMAT = 'MMM dd, yyyy';
const TIME_DISPLAY_FORMAT = 'h:mm:ssa';
const DATE_TIME_DISPLAY_FORMAT = 'MMM dd, yyyy h:mma';
const DATE_TIME_SECONDS_DISPLAY_FORMAT = 'MMM dd, yyyy h:mm:ssa';
/**
 *
 * @returns string in the format of 'MMM dd, yyyy' or empty string if no date
 * @param dateObj
 * @param isDateTime
 */
export const formatDateToDisplay = (
    dateObj: MXDTimestamp | string | undefined,
    isDateTime?: TimeType,
) => {
    if (typeof dateObj === 'string') {
        return dateObj;
    }
    if (!dateObj?.date) {
        return '';
    }
    let dateTimeType;
    switch (isDateTime) {
        case TimeType.MINUTES:
            dateTimeType = DATE_TIME_DISPLAY_FORMAT;
            break;
        case TimeType.SECONDS:
            dateTimeType = DATE_TIME_SECONDS_DISPLAY_FORMAT;
            break;
        case TimeType.TIME:
            dateTimeType = TIME_DISPLAY_FORMAT;
            break;
        default:
            dateTimeType = DATE_DISPLAY_FORMAT;
            break;
    }

    return format(new Date(dateObj.date), dateTimeType);
};
