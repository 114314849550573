import { differenceInDays } from 'date-fns';
import { useSelector } from 'react-redux';
import {
    EventLinkedObjectDetailType,
    EventLinkedObjectType,
    EventsObject,
    projectState,
    subscriptionState,
    userState,
} from 'state-domains/domain';
import { useMemo } from 'react';

import { getItemName, getItemUrl } from '../EventPostHeader';

const JOB_DAY_OLD_THRESHOLD = 14;

export function useEventLinkedObjectDetails(event: EventsObject) {
    const {
        selectors: { projects: projectsSelector },
    } = projectState;
    const {
        selectors: { activityObject: getActivityByIdSelector },
    } = subscriptionState;
    const {
        selectors: { currentUserId: currentUserIdSelector },
    } = userState;

    const projects = useSelector(projectsSelector);
    const projectFromRedux = projects[event?.project ?? ''];
    const activity = useSelector(getActivityByIdSelector(event?.activity ?? ''));
    const currentUserId = useSelector(currentUserIdSelector);

    return useMemo(() => {
        let project = event?.project ? projectFromRedux : null;
        if (!project && event?.referenceType === EventLinkedObjectType.PROJECT) {
            project = projects[event?.referenceId ?? ''];
        }
        if (!event || (!project && event?.referenceType !== EventLinkedObjectType.JOB_COMPLETED)) {
            return { itemName: event?.referenceName ?? '', itemUrl: '' };
        }

        const projectId = project?.id ?? '';
        const referenceId = event?.referenceId ?? '';
        let referenceType = event?.referenceType ?? '';
        const referenceName = event?.referenceName ?? '';
        const detail = event?.detail;
        const updatedAt = event?.updatedAt.date ?? (event as any).updatedAtEpoch;

        if (referenceType === EventLinkedObjectType.JOB_COMPLETED && updatedAt) {
            const daysDifference = differenceInDays(new Date(), updatedAt);

            if (daysDifference > JOB_DAY_OLD_THRESHOLD || event.createdBy !== currentUserId) {
                return { itemName: '', itemUrl: '' };
            }

            return {
                itemName: getItemName(
                    '',
                    '',
                    '',
                    referenceType === EventLinkedObjectType.JOB_COMPLETED,
                ),
                itemUrl: getItemUrl(referenceType as EventLinkedObjectType, referenceId, projectId),
            };
        }

        if (
            !referenceId ||
            !referenceType ||
            !referenceName ||
            (referenceType === EventLinkedObjectType.JOB_COMPLETED &&
                detail === EventLinkedObjectDetailType.EXPORT)
        ) {
            return { itemName: '', itemUrl: '' };
        }
        if (referenceType === EventLinkedObjectType.DRILLHOLE && activity) {
            referenceType =
                activity?.type === 'drilling'
                    ? EventLinkedObjectType.DRILLHOLE
                    : EventLinkedObjectType.POINT;
        }

        return {
            itemName: getItemName(
                project?.name,
                activity?.name,
                referenceName,
                referenceType === EventLinkedObjectType.JOB_COMPLETED,
            ),
            itemUrl: getItemUrl(referenceType as EventLinkedObjectType, referenceId, projectId),
        };
    }, [event]);
}
