import { ArrowDown, ArrowUp } from '@local/web-design-system';
import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useStyles } from './Comments.styles';
import { i18n } from './Comments.i18n';

export const RepliesDropdown = ({
    commentsCount,
    isOpen,
    setOpen,
}: {
    commentsCount: number;
    isOpen: boolean;
    setOpen: Function;
}) => {
    const { classes } = useStyles();
    const intl = useIntl();

    const toggleCommentsOpen = useCallback(() => setOpen(!isOpen), [isOpen]);

    const commentsMessage = useMemo(() => {
        if (commentsCount === 0) return '';
        return commentsCount === 1
            ? intl.formatMessage(i18n.singleReply)
            : intl.formatMessage(i18n.multipleReplies, { count: commentsCount });
    }, [commentsCount]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.dimText} paddingRight="4px" noWrap>
                {commentsMessage}
            </Typography>
            {commentsCount > 0 &&
                (isOpen ? (
                    <ArrowUp
                        onClick={toggleCommentsOpen}
                        className={classNames(classes.dimText, classes.arrow)}
                    />
                ) : (
                    <ArrowDown
                        onClick={toggleCommentsOpen}
                        className={classNames(classes.dimText, classes.arrow)}
                    />
                ))}
        </Box>
    );
};
