import { partition } from 'lodash-es';
import { MapLayer } from 'src/components/Drillhole/DrillholeMap/DrillholeMap.types';

import {
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    CLIENT_SIDE_PAGINATION_LIMIT,
} from '../../../constants';
import { BaseAction } from '../../../types';
import {
    LIST_PROJECTS,
    LOGOUT_REQUEST,
    PIN_PROJECT,
    SET_SEARCH_TERM,
    REFRESH_STATE,
    MAP_VIEW_STATE,
    LOAD_OVERVIEW_COLLARS_WITH_COUNT,
    CLEAR_OVERVIEW_COLLARS,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE,
    CLEAR_PROJECT_LOAD_STATE,
    IMPORT_COLLARS,
    CLEAR_IMPORT_COLLARS,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    staleReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { MapTypes, Project, ProjectError, ProjectFileGroup, ProjectState } from '../types';
import { getAllUsersForProject } from '../utils';

export const INITIAL_OVERVIEW_COLLARS_STATE = {
    status: LOAD_STATUS_STALE,
    error: null,
    items: {},
    count: 0,
};

export const INITIAL_STATE: ProjectState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    projectLoadState: { error: null, status: LOAD_STATUS_PENDING },
    pinProjectState: { error: null, status: LOAD_STATUS_PENDING },
    projects: {},
    allProjects: {},
    projectFileGroups: {},
    searchTerm: '',
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
    mapType: MapLayer.SATELLITE,
    overviewMapCollars: INITIAL_OVERVIEW_COLLARS_STATE,
    importCollars: { error: null, status: LOAD_STATUS_STALE },
};

function setSearchTermReducer(
    state: ProjectState,
    action: BaseAction<{ searchTerm: string; offset: number; limit: number }>,
): ProjectState {
    const { searchTerm, offset, limit } = action.payload;
    return {
        ...state,
        searchTerm,
        offset,
        limit,
    };
}

const projectsPendingReducer = (state: ProjectState): ProjectState =>
    pendingReducer({
        ...state,
        projectLoadState: pendingReducer({}),
    });

const listProjectsSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const {
        payload: { projectsList, userId, projectFileGroups = [] },
    } = actions;

    // Why is the projectsList sometimes undefined?
    const filteredProjects = (projectsList ?? []).filter(
        (p: Project) => getAllUsersForProject(p).includes(userId) && p.isvalid,
    );

    return {
        ...state,
        projectFileGroups: Object.assign(
            { ...(state.projectFileGroups ?? {}) },
            ...projectFileGroups.map((x: ProjectFileGroup) => ({ [x.id]: x })),
        ),
        allProjects: Object.assign(
            { ...(state.allProjects ?? {}) },
            ...projectsList.map((x: Project) => ({ [x.id]: x })),
        ),
        projects: Object.assign(
            { ...(state.projects ?? {}) },
            ...filteredProjects.map((x: Project) => ({ [x.id]: x })),
        ),
        projectLoadState: completeReducer({}),
    };
};

const projectsFailureReducer = (state: ProjectState, action: BaseAction<ProjectError>) => {
    const { error } = action.payload;
    return { ...state, projectLoadState: failureReducer({ ...state.projectLoadState, error }) };
};

const setProjectPinPendingReducer = (state: ProjectState): ProjectState =>
    pendingReducer({
        ...state,
        pinProjectState: pendingReducer({}),
    });

const setProjectPinSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const { projects } = state;
    const projectsToUpdate = { ...projects };
    const {
        payload: { projectId, pin },
    } = actions;
    projectsToUpdate[projectId].pinned = pin;
    return { ...state, projects: projectsToUpdate, pinProjectState: completeReducer({}) };
};

const setProjectPinFailureReducer = (state: ProjectState, action: BaseAction<ProjectError>) => {
    const { error } = action.payload;
    return { ...state, pinProjectState: failureReducer({ ...state.pinProjectState, error }) };
};

const resetProjectStateReducer = (_state: ProjectState, _action: BaseAction) => ({
    ...INITIAL_STATE,
});

const selectedMapView = (state: ProjectState, action: BaseAction<MapTypes>) => {
    const type = action.payload.mapType;
    return { ...state, mapType: type };
};

const loadOverviewMapCollarsPendingReducer = (state: ProjectState): ProjectState => {
    const { overviewMapCollars: overviewMapCollarsState } = state;
    return {
        ...state,
        overviewMapCollars: pendingReducer({ ...overviewMapCollarsState }),
    };
};

const loadOverviewMapCollarsSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const { payload } = actions;
    const result = partition(payload, (x) => Array.isArray(x));
    const items = result[0];
    const sizes = result[1];

    const { overviewMapCollars: overviewMapCollarsState } = state;
    return {
        ...state,
        overviewMapCollars: completeReducer({
            ...overviewMapCollarsState,
            items: items.flat(),
            count: sizes.reduce(
                (accumulator: number, currentValue: { size: number }) =>
                    accumulator + (currentValue.size ?? 0),
                0,
            ),
        }),
    };
};

const loadOverviewMapCollarsFailureReducer = (
    state: ProjectState,
    action: BaseAction<ProjectError>,
) => {
    const { error } = action.payload;
    const { overviewMapCollars: overviewMapCollarsState } = state;
    return {
        ...state,
        overviewMapCollars: failureReducer({ ...overviewMapCollarsState, error }),
    };
};

const clearOverviewCollarsReducer = (state: ProjectState, _action: BaseAction) => ({
    ...state,
    overviewMapCollars: { ...INITIAL_OVERVIEW_COLLARS_STATE },
});

const clearProjectLoadState = (state: ProjectState, _action: BaseAction) => ({
    ...state,
    projectLoadState: { error: null, status: LOAD_STATUS_PENDING },
});

const cudCollectionItemCompleteReducer = (state: ProjectState, action: BaseAction) => {
    // Create/Update/Delete an item in project state collection. Operates on the complete
    // first level object in collection
    const { id, item, type, collectionName } = action.payload;
    const collection = { ...(state as any)[collectionName as keyof ProjectState] };
    if (type === 'DELETE') {
        delete collection[id];
    } else {
        collection[id] = item;
    }

    return {
        ...state,
        [collectionName]: collection,
    };
};

const setImportCollarsPendingReducer = (state: ProjectState) => ({
    ...state,
    importCollars: pendingReducer({}),
});

const setImportCollarsSuccessReducer = (state: ProjectState, _actions: BaseAction) => ({
    ...state,
    importCollars: completeReducer({}),
});

const setImportCollarsFailureReducer = (state: ProjectState, action: BaseAction<any>) => {
    const { error, response } = action.payload;
    return {
        ...state,
        importCollars: failureReducer({
            error: error ?? {
                message: response.error,
            },
        }),
    };
};

const clearImportCollarState = (state: ProjectState, _action: BaseAction<any>) => ({
    ...state,
    importCollars: staleReducer({}),
});

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(LIST_PROJECTS)]: projectsPendingReducer,
    [typeComplete(LIST_PROJECTS)]: listProjectsSuccessReducer,
    [typeFail(LIST_PROJECTS)]: projectsFailureReducer,

    [typePending(PIN_PROJECT)]: setProjectPinPendingReducer,
    [typeComplete(PIN_PROJECT)]: setProjectPinSuccessReducer,
    [typeFail(PIN_PROJECT)]: setProjectPinFailureReducer,

    [SET_SEARCH_TERM]: setSearchTermReducer,

    [typeComplete(LOGOUT_REQUEST)]: () => INITIAL_STATE,

    [REFRESH_STATE]: resetProjectStateReducer,
    [MAP_VIEW_STATE]: selectedMapView,

    [typePending(LOAD_OVERVIEW_COLLARS_WITH_COUNT)]: loadOverviewMapCollarsPendingReducer,
    [typeComplete(LOAD_OVERVIEW_COLLARS_WITH_COUNT)]: loadOverviewMapCollarsSuccessReducer,
    [typeFail(LOAD_OVERVIEW_COLLARS_WITH_COUNT)]: loadOverviewMapCollarsFailureReducer,
    [CLEAR_OVERVIEW_COLLARS]: clearOverviewCollarsReducer,
    [typeComplete(CLEAR_PROJECT_LOAD_STATE)]: clearProjectLoadState,
    [typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE)]:
        cudCollectionItemCompleteReducer,

    [typePending(IMPORT_COLLARS)]: setImportCollarsPendingReducer,
    [typeComplete(IMPORT_COLLARS)]: setImportCollarsSuccessReducer,
    [typeFail(IMPORT_COLLARS)]: setImportCollarsFailureReducer,

    [CLEAR_IMPORT_COLLARS]: clearImportCollarState,
});
