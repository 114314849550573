import { i18nActionConstants } from 'state-domains/constants/mxdeposit.i18n';

export const i18n = {
    replyButton: {
        id: 'posts-comment-section.reply',
        defaultMessage: 'Reply',
    },
    noReplies: {
        id: 'posts-comment-section.no-replies',
        defaultMessage: 'No Replies',
    },
    singleReply: {
        id: 'posts-comment-section.single-reply',
        defaultMessage: '1 Reply',
    },
    multipleReplies: {
        id: 'posts-comment-section.multiple-replies',
        defaultMessage: '{count} Replies',
    },
    commentPlaceholder: {
        id: 'posts-comment-section.placeholder',
        defaultMessage: 'Post a reply',
    },
    cancel: i18nActionConstants.cancel,
    post: i18nActionConstants.post,
};
