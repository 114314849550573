import {
    getDispatchUrl,
    getLabCertUrl,
    getSelectedDrillHoleUrl,
    getSelectedPointUrl,
    getSelectedProjectUrl,
    JOBS_PATH,
} from 'src/routes';
import { EventLinkedObjectType, PostLinkedObjectType } from 'state-domains/domain/events';

export enum ReferenceType {
    POST = 'post',
    EVENT = 'event',
}

export const getItemUrl = (
    objectType?: EventLinkedObjectType | PostLinkedObjectType,
    objectId?: string,
    projectId?: string,
) => {
    if (!objectId) {
        return '';
    }
    switch (objectType) {
        case EventLinkedObjectType.PROJECT:
            return getSelectedProjectUrl(objectId);
        case PostLinkedObjectType.DRILLHOLE:
        case EventLinkedObjectType.DRILLHOLE:
            return projectId ? getSelectedDrillHoleUrl(projectId, objectId) : '';
        case PostLinkedObjectType.POINT:
        case EventLinkedObjectType.POINT:
            return projectId ? getSelectedPointUrl(projectId, objectId) : '';
        case PostLinkedObjectType.DISPATCH:
        case EventLinkedObjectType.DISPATCH:
            return getDispatchUrl(objectId);
        case PostLinkedObjectType.LAB_CERT:
        case EventLinkedObjectType.LAB_CERT:
            return getLabCertUrl(objectId);
        case EventLinkedObjectType.JOB_COMPLETED:
            return JOBS_PATH;
        default:
            return '';
    }
};

export const getItemName = (
    projectName?: string,
    activityName?: string,
    objectName?: string,
    isExport = false,
) => {
    if (isExport) return 'View Jobs';
    if (!objectName) return '';
    if (!projectName || !activityName) return objectName;
    return `${projectName} / ${activityName} / ${objectName}`;
};
