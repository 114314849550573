import { sanitizeWithoutHtml } from '@local/web-design-system/dist/components/HtmlSection/sanitizeHtml';
import { cloneDeep } from 'lodash-es';
import { WGS84_GRID_ID } from 'state-domains/constants';
import { ProjectBoundary } from 'state-domains/domain';
import { toFixed } from 'src/utilities';

export const convertIntToLabel = (num: number, val: string) => {
    if (num > 25) {
        val = convertIntToLabel(Math.floor(num / 26) - 1, val);
        num %= 26;
    }
    return `${val}${String.fromCharCode(65 + (num % 26))}`;
};

const toDegreesMinutesSeconds = (coordinate: any, direction: string) => {
    const degrees = parseInt(coordinate, 10);
    const t1: any = (coordinate - degrees) * 60;
    const minutes = parseInt(t1, 10);
    const seconds = Number(toFixed((t1 - minutes) * 60, 1));

    return `${Math.abs(degrees)}° ${Math.abs(minutes)}′ ${Math.abs(seconds)}″${direction}`;
};

export const displayCoordinateValue = (
    value: any,
    selectedBoundarySystem: string,
    decimalSelected: boolean,
    isEastWest: boolean,
) => {
    let num = 0;
    let direction = '';
    const degree = selectedBoundarySystem === WGS84_GRID_ID ? '°' : '';

    const precision = selectedBoundarySystem === WGS84_GRID_ID ? 4 : 2;
    num = Number(toFixed(value, precision));

    if (num < 0) {
        num *= -1;
        direction = isEastWest ? 'W' : 'S';
    } else {
        direction = isEastWest ? 'E' : 'N';
    }

    if (selectedBoundarySystem === WGS84_GRID_ID && !decimalSelected) {
        return toDegreesMinutesSeconds(value, direction);
    }

    return `${toFixed(num, precision)}${degree}${direction}`;
};

export const formattedConvertedBoundaries = (
    originalBoundaryData: ProjectBoundary[],
    convertedBoundaryData: any[],
) => {
    const newBounds: any[] = [];
    const convertedData = cloneDeep(convertedBoundaryData);
    if ((convertedData ?? []).length) {
        originalBoundaryData.forEach((x: any) => {
            const obj: any = {};
            obj.name = sanitizeWithoutHtml(x.name).trim();
            obj.coordinates = [...(convertedData ?? []).splice(0, x.coordinates[0].length)];
            newBounds.push(obj);
        });
        return newBounds;
    }
    return [];
};
