import { ShimState } from 'state-domains';
import {
    authState,
    userState,
    notificationsState,
    snackbarNotificationsState,
    drillholeState,
    projectState,
    subscriptionState,
    mapState,
    integrationState,
    eventsState,
    configurationState,
} from 'state-domains/domain';
import { ApiClient } from 'state-domains/utils/network/service/ApiClient';
import { injectReducer, getStore, setupStore } from 'state-domains/store';

import { UserPortalConfig } from '../config';

import * as drawerState from './Drawer';

export const initStore = () => {
    setupStore(ApiClient.middleware);
    injectReducer('drawer', drawerState.reducer);
    injectReducer('authentication', authState.reducer);
    injectReducer('user', userState.reducer);
    injectReducer('notifications', notificationsState.reducer);
    injectReducer('snackbarNotifications', snackbarNotificationsState.reducer);
    injectReducer('drillhole', drillholeState.reducer);
    injectReducer('project', projectState.reducer);
    injectReducer('subscription', subscriptionState.reducer);
    injectReducer('azureMapToken', mapState.reducer);
    injectReducer('integrations', integrationState.reducer);
    injectReducer('events', eventsState.reducer);
    injectReducer('configuration', configurationState.reducer);
    return getStore();
};

export type AppStore = ReturnType<typeof initStore>;

export interface UserPortalState extends ShimState {
    config: UserPortalConfig;
    drawer: drawerState.DrawerState;
}

export interface AsyncProps {
    isPending: boolean;
    isFailed: boolean;
    isComplete: boolean;
}
