import { NotificationType } from '@local/web-design-system';
import { v4 } from 'uuid';

import { SnackbarNotification } from '../snackbarNotifications/types';
import { UserWithType } from '../subscription';

import { EventNotificationTypes, EventsObject } from './types';

export const generateSnackBarNotifications = (
    event: EventsObject,
    notificationType: EventNotificationTypes,
    senderId: string,
    userId: string,
    users: Record<string, UserWithType>,
) => {
    const notifications: SnackbarNotification[] = [];
    const isPost = event.eventType === 'post';

    if (senderId !== userId) {
        switch (notificationType) {
            case EventNotificationTypes.NEW_EVENT: {
                if (isPost) {
                    if (
                        event.post.shareType === 'private' &&
                        event.post.shareWith.includes(userId)
                    ) {
                        notifications.push({
                            type: NotificationType.INFO,
                            descriptor: v4(),
                            modalData: { data: event, title: 'newPost' },
                            i18nKey: 'sharedWithYou',
                            i18nValues: {
                                user: determineUserName(event.createdBy, users),
                            },
                        });
                    }

                    if ((event.post.taggedUsers ?? []).includes(userId)) {
                        notifications.push(
                            buildTaggedUsersNotification(
                                event.post.taggedUsers,
                                senderId,
                                userId,
                                users,
                                { data: event, title: 'taggedPost' },
                            ),
                        );
                    }
                }
                break;
            }
            case EventNotificationTypes.UPDATED_POST: {
                if ((event.post.taggedUsers ?? []).includes(userId)) {
                    notifications.push(
                        buildTaggedUsersNotification(
                            event.post.taggedUsers,
                            senderId,
                            userId,
                            users,
                            { data: event, title: 'updatedPost' },
                        ),
                    );
                }
                break;
            }
            case EventNotificationTypes.NEW_REPLY: {
                const lastReply = (event.post.replies ?? [])[event.post.replies.length - 1];
                const usersToNotify = [
                    event.createdBy,
                    ...(event.post.shareWith ?? []),
                    ...(event.post.taggedUsers ?? []),
                    ...(event.post.replies ?? []).reduce((accum, value) => {
                        accum.push(value.userId);
                        return accum;
                    }, [] as string[]),
                ];

                if ((lastReply?.taggedUsers ?? []).includes(userId)) {
                    notifications.push(
                        buildTaggedUsersNotification(
                            lastReply.taggedUsers,
                            senderId,
                            userId,
                            users,
                            { data: event, title: isPost ? 'taggedPost' : 'taggedEvent' },
                        ),
                    );
                }

                if (usersToNotify.includes(userId)) {
                    notifications.push(
                        buildNewReplyNotification(event.createdBy, senderId, userId, users, event),
                    );
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    return notifications;
};

const buildNewReplyNotification = (
    createdBy: string,
    senderId: string,
    userId: string,
    users: Record<string, UserWithType>,
    event: EventsObject,
) => {
    let creator = `${determineUserName(createdBy, users)}'s`;

    if (createdBy === userId) {
        creator = 'your';
    } else if (createdBy === senderId) {
        creator = 'their';
    }

    return {
        type: NotificationType.INFO,
        descriptor: v4(),
        modalData: { data: event, title: event.eventType === 'post' ? 'replyPost' : 'replyEvent' },
        i18nKey: 'newReply',
        i18nValues: {
            user: determineUserName(senderId, users),
            creator,
        },
    };
};

const buildTaggedUsersNotification = (
    taggedUsers: string[],
    senderId: string,
    userId: string,
    users: Record<string, UserWithType>,
    data: { data: EventsObject; title: string },
): SnackbarNotification => {
    let i18nKey = '';
    const filteredTaggedUsers = taggedUsers.filter((x) => x !== userId);

    if (filteredTaggedUsers.length) {
        i18nKey = filteredTaggedUsers.length >= 2 ? 'mentionedMultiple' : 'mentionedOne';
    } else {
        i18nKey = 'mentionedYou';
    }

    return {
        type: NotificationType.INFO,
        descriptor: v4(),
        i18nKey,
        modalData: data,
        i18nValues: {
            user: determineUserName(senderId, users),
            mentions: filteredTaggedUsers.length,
        },
    };
};

const determineUserName = (userId: string, users: Record<string, UserWithType>) =>
    users[userId]?.profile?.name ?? users[userId]?.email ?? '';
