import { AsyncState } from 'state-domains/types';

import { MXDTimestamp } from '../utils';

export interface ActionSubState extends AsyncState {
    linkedObjectItems?: Option[];
    shouldReload?: boolean;
}

export interface EventsSubState extends AsyncState {
    items: Record<string, EventsObject>;
    count: number;
    actionState: ActionSubState;
}

export interface PostsSubState extends AsyncState {
    items: Record<string, PostsObject>;
    count: number;
    actionState: ActionSubState;
}

export interface Option {
    id: string;
    name: string;
}
export interface EventsState extends AsyncState {
    jobs: Record<string, Job>;
    searchTerm?: string;
    offset?: number;
    limit?: number;
    eventsState: EventsSubState;
    postsState: PostsSubState;
}

interface JobUser {
    user: string;
    id: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
}

export type JobUsers = Record<string, JobUser>;

export interface Job {
    id: string;
    parameters: {
        body: {
            project?: string;
            destination?: string[];
            exportTableIds?: { tableIds: string[]; tableViewIds: string[] };
        };
    };
    progressPercentage?: number;
    name: string;
    error: string;
    createdAt?: MXDTimestamp;
    createdBy?: string;
    status: string;
    result?: string;
    type: string;
    evoObjectLink?: string;
}

export interface Jobs {
    Job: Job[];
}

export interface JobError {
    error: Error;
}

export interface JobCompleteEvent {
    detail: string;
    referenceDetail: string;
    detail1: string;
}

export enum JobType {
    Export = 'Export',
    ListExport = 'List-Export',
    Import = 'Import',
    TableExport = 'Table-Export',
    RankedColumnRecompute = 'Ranked-Column-Recompute',
    Recalculation = 'Recalculation',
}

export enum JobStatus {
    FAILED = 'Failed',
    EXPIRED = 'Expired',
    CANCELLED = 'Cancelled',
    SUCCEEDED = 'Succeeded',
    SUBMITTED = 'Submitted',
    PENDING = 'Pending',
    RUNNABLE = 'Runnable',
    STARTING = 'Starting',
    RUNNING = 'Running',
}

export interface EventsObject {
    id: string;
    activity: string | null;
    description: string | null;
    detail: string;
    detail1: string;
    eventType: string;
    linkedId: string | null;
    linkedName: string | null;
    linkedType: string | null;
    name: string;
    project: string | null;
    referenceAncestors: Record<
        string,
        {
            type: string;
            id: string;
            name: string;
        }
    > | null;
    referenceDetail: string | null;
    referenceId: string | null;
    referenceName: string | null;
    referenceType: string | null;
    updatedByUser: {
        profile: {
            name: string;
            initials: string;
            color: string;
        };
    };
    createdBy: string;
    createdAt: MXDTimestamp;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    post: PostsObject;
}

export interface CreatePostPayload {
    content: string;
    share_type: PostShareType;
    share_with?: string[];
    linked_object?: PostsLinkedObjectPayload;
    newAttachments?: File[];
}

export interface PostsObject {
    project: string;
    activity: string;
    name: string;
    id: string;
    type: string;
    content: string;
    attachments: Record<string, FileObj>;
    userId: string;
    replies: PostReply[];
    linkedObject?: LinkedObject;
    shareType: string;
    shareWith: string[];
    taggedUsers: string[];
    postedDatetime: MXDTimestamp;
    createdBy: string;
    createdAt: MXDTimestamp;
    updatedBy: string;
    updatedAt: MXDTimestamp;
}
export interface FileObj {
    contentType: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    file: string;
    id: string;
    originalFilename: string;
    size: number;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}

export interface PostReply {
    userId: string;
    content: string;
    postedDatetime: MXDTimestamp;
    taggedUsers: any[];
}

export interface PostsLinkedObjectPayload extends Omit<LinkedObject, 'objectType'> {
    object_type: PostLinkedObjectType;
}

export interface AttachFileToPostResponse {
    file: string;
    contentType: string;
    originalFilename: string;
    size: number;
}

export enum EventType {
    POST = 'post',
    EVENT = 'event',
}

export enum PostShareType {
    public = 'public',
    private = 'private',
}

export interface UpdatePostPayload {
    id: string;
    content: string;
    attachments?: string[];
    newAttachments?: File[];
    linkedObject?: LinkedObject | null;
    shareType: string;
    shareWith: string[];
}

export interface LinkedObjectBase {
    objectType: PostLinkedObjectType;
    project: string;
    activity: string;
    object: string;
    projectName: string;
    activityName: string;
    objectName: string;
}

export enum PostLinkedObjectType {
    DRILLHOLE = 'Drill hole',
    POINT = 'Point',
    DISPATCH = 'Dispatch',
    LAB_CERT = 'Lab cert',
}

export enum EventLinkedObjectType {
    DRILLHOLE = 'drillhole',
    POINT = 'point',
    DISPATCH = 'sample_dispatch',
    LAB_CERT = 'lab_certificate',
    USER = 'user',
    JOB_COMPLETED = 'job_completed',
    ACTIVITY = 'activity',
    PROJECT = 'project',
}

export enum EventLinkedObjectDetailType {
    EXPORT = 'export',
}

export interface LinkedObjectWithCollar extends LinkedObjectBase {
    collar: string;
}

export interface LinkedObjectWithDispatch extends LinkedObjectBase {
    dispatch: string;
}

export interface LinkedObjectWithLabCert extends LinkedObjectBase {
    labCert: string;
}

export type LinkedObject =
    | LinkedObjectWithCollar
    | LinkedObjectWithDispatch
    | LinkedObjectWithLabCert;

export enum EventNotificationTypes {
    NEW_EVENT = 'newEvent',
    UPDATED_POST = 'updatedPost',
    NEW_REPLY = 'newReply',
    DELETED_POST = 'deletedPost',
}
