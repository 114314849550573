import { isEmpty } from 'lodash-es';
import {
    ACTIVITY_TYPES,
    COORDINATES_ID,
    HEADER_VALUES,
    MODULES,
    SAMPLE_RESULTS_KEY,
} from 'state-domains/constants';
import {
    Activity,
    ActivityMap,
    DefaultPermission,
    determineSpecialTable,
    Header,
    PermissionModuleAction,
    Project,
    PROJECT_ACTIVITY_PERMISSIONS,
    ProjectActivityPermissions,
    Table,
    TableView,
    UserType,
} from 'state-domains/domain';
import { getTablesForUserGroups } from 'state-domains/domain/project/utils';
import { PathConstants } from 'src/routes';
import { ReadOnlyReasons } from 'src/utilities/types';

export const permissionMap = {
    [MODULES.ACTIVITY_FEED]: PROJECT_ACTIVITY_PERMISSIONS.ACTIVITY_FEED,
    [MODULES.PROJECT_SUMMARY]: PROJECT_ACTIVITY_PERMISSIONS.PROJECT_SUMMARY,
    [MODULES.SAMPLE_DISPATCH]: PROJECT_ACTIVITY_PERMISSIONS.SAMPLE_DISPATCH,
    [MODULES.ASSAYS_QAQC]: PROJECT_ACTIVITY_PERMISSIONS.ASSAYS_QAQC,
    [MODULES.EXPORT]: PROJECT_ACTIVITY_PERMISSIONS.EXPORT,
    [MODULES.REPORTS]: PROJECT_ACTIVITY_PERMISSIONS.REPORTS,
    [MODULES.DRILL_HOLES_CREATE]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_CREATE,
    [MODULES.DRILL_HOLES_DELETE]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_DELETE,
    [MODULES.DRILL_HOLES_UNASSIGN]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_UNASSIGN,
    [MODULES.DRILL_HOLES_STATE]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_STATE,
    [MODULES.DRILL_HOLES]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR,
    [MODULES.POINT_SAMPLE_CREATE]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_CREATE,
    [MODULES.POINT_SAMPLES_DELETE]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_DELETE,
    [MODULES.POINT_SAMPLES_UNASSIGN]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_UNASSIGN,
    [MODULES.POINT_SAMPLES_STATE]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR_STATE,
    [MODULES.POINT_SAMPLES]: PROJECT_ACTIVITY_PERMISSIONS.COLLAR,
    [MODULES.IMPORT_STATE]: PROJECT_ACTIVITY_PERMISSIONS.IMPORT_STATE,
    [MODULES.IMPORT]: PROJECT_ACTIVITY_PERMISSIONS.IMPORT,
};

const buildPermissionsForActivity = (
    projectActivity: Activity,
    activity: ActivityMap,
    defaultPermissions: Record<string, DefaultPermission>,
    userInfo: { id: string; type: string },
) =>
    Object.values(defaultPermissions ?? {})
        .filter(
            (y) =>
                y.id !==
                (activity.type === ACTIVITY_TYPES.DRILLING
                    ? MODULES.POINT_SAMPLES
                    : MODULES.DRILL_HOLES),
        )
        .reduce((accum, y) => {
            const { id, type } = userInfo;
            const activityPermission = projectActivity.users?.[id]?.userPermissions?.[y.id];
            const access = activityPermission?.access ?? y.access;

            if (access) {
                accum |= permissionMap[y.id];

                const activityModuleActions = activityPermission?.moduleActions ?? [];

                (y.moduleActions ?? []).forEach((z) => {
                    const activityAction = activityModuleActions.find((a) => a.id === z.id);

                    if (
                        type !== UserType.Reviewer &&
                        (activityAction?.access ??
                            z.access ??
                            z[type as keyof PermissionModuleAction])
                    ) {
                        if (z.id === MODULES.POINT_SAMPLES_STATE) {
                            accum |=
                                y.id === MODULES.POINT_SAMPLES
                                    ? PROJECT_ACTIVITY_PERMISSIONS.COLLAR_STATE
                                    : PROJECT_ACTIVITY_PERMISSIONS.IMPORT_STATE;
                        } else {
                            accum |= permissionMap[z.id];
                        }
                    }
                });
            }

            return accum;
        }, 0);

export const constructProjectActivityPermissions = (
    project: Project,
    activities: Record<string, ActivityMap>,
    defaultPermissions: Record<string, DefaultPermission>,
    userInfo: { id: string; type: string }[],
): Record<string, ProjectActivityPermissions> => {
    const sortedProjects = Object.values(project.activities ?? {}).sort(
        (a, b) => a.index - b.index,
    );

    return Object.fromEntries(
        userInfo.map((u) => {
            const { id } = u;
            const activityPermissionMap: ProjectActivityPermissions = sortedProjects
                .filter((x) => project?.users?.[id] || x.users?.[id])
                .reduce((accum, x) => {
                    const activity = activities[x.activity];
                    let permissions = buildPermissionsForActivity(
                        x,
                        activity,
                        defaultPermissions,
                        u,
                    );

                    /*  In our app we are coupling import permissions with the collar permisisons. 
                        Need to override import to false if collar permission isnt present. 
                        Ideally in the future we will rework permissions in the database and we can remove this override.
                    */
                    if (!(permissions & PROJECT_ACTIVITY_PERMISSIONS.COLLAR)) {
                        permissions = permissions & ~PROJECT_ACTIVITY_PERMISSIONS.IMPORT;
                    }

                    accum[activity.id] = {
                        permissions,
                        type: activity.type as ACTIVITY_TYPES,
                        name: activity.name,
                    };

                    return accum;
                }, {} as any);

            return [u.id, activityPermissionMap];
        }),
    );
};

export const getTableInfoForActivity = (
    project: Project,
    activity: ActivityMap,
    userInfo: { id: string; type: string },
    tableViews: Record<string, TableView>,
    tables: Record<string, Table>,
    headers: Record<string, Header>,
) => {
    const activityHasNoGroups = isEmpty(activity.groups);
    const isReviewer = userInfo.type === UserType.Reviewer;
    const userInGroups = getTablesForUserGroups(project, activity, userInfo.id);
    const sampleResultsObj = {
        subTableInfo: {
            id: SAMPLE_RESULTS_KEY,
            isEditable: false,
        },
    };

    return getTablesInActivity(activity)
        .filter((x) => activityHasNoGroups || Object.keys(userInGroups).includes(x))
        .reduce((accum, value) => {
            const item = tableViews[value] ?? headers[value];

            if (item) {
                const headerInfo = [
                    activity.header === item.id ? HEADER_VALUES.HEADER : HEADER_VALUES.COORDINATES,
                ];
                const isEditable = (activityHasNoGroups || userInGroups[value]) && !isReviewer;

                accum[value] = {
                    id: item.id,
                    name: item.name,
                    label: item.label,
                    allowDupes: !!item.allowDupes,
                    allowGaps: !!item.allowGaps,
                    allowOverlaps: !!item.allowOverlaps,
                    linkedToLithology: !!item.linkedToLithology,
                    lithologyTableView: !!item.lithologyTableView,
                    intervalType: item.intervalType ?? 'other',
                    isEditable,
                    reasonObject: {
                        reasons: [
                            ...(isReviewer ? [ReadOnlyReasons.REVIEWER] : []),
                            ...(!activityHasNoGroups && !userInGroups[value]
                                ? [ReadOnlyReasons.ACTIVITY_GROUP]
                                : []),
                        ],
                        params: {},
                    },
                    specialTables: item.id.includes(activity.header)
                        ? headerInfo
                        : (determineSpecialTable(item, tables)?.types ?? []),
                    ...(value === activity.samples && sampleResultsObj),
                };
            }

            return accum;
        }, {} as any);
};

export const getTablesInActivity = (activity: ActivityMap) => {
    const tableArray = [
        ...(activity.samples
            ? [{ id: String(activity.samples), index: activity.samplesListSpecs?.index ?? 0 }]
            : []),
        ...(activity.survey
            ? [{ id: String(activity.survey), index: activity.surveyListSpecs?.index ?? 0 }]
            : []),
        ...(activity.lithology
            ? [{ id: String(activity.lithology), index: activity.lithologyListSpecs?.index ?? 0 }]
            : []),
        ...Object.values(activity.tableViewsSpecs ?? {}).map((x) => ({
            id: x.id,
            index: x.index ?? 0,
        })),
    ];

    return [
        ...(activity.header
            ? [String(activity.header), `${activity.header}-${COORDINATES_ID}`]
            : []),
        ...tableArray.sort((a, b) => a.index - b.index).map((x) => x.id),
    ];
};

export const determineModuleType = (path: string) => {
    let type = ACTIVITY_TYPES.ALL;

    if (path.includes(PathConstants.PROJECTS.DRILLHOLE.ROOT)) {
        type = ACTIVITY_TYPES.DRILLING;
    } else if (path.includes(PathConstants.PROJECTS.POINTS.ROOT)) {
        type = ACTIVITY_TYPES.POINT;
    }

    return type;
};
