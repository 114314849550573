import { actions } from './actions';
import { INITIAL_STATE, reducer } from './reducer';
import { selectors } from './selectors';

export const eventsState = {
    INITIAL_STATE,
    actions,
    reducer,
    selectors,
};
