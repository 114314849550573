import ReactLightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Download from 'yet-another-react-lightbox/plugins/download';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import React, { forwardRef, useCallback, useImperativeHandle, useState, memo } from 'react';
import { baseApiUrl } from 'state-domains/network';
import { useIntl } from 'react-intl';
import { FileObj } from 'state-domains/domain';

import { LightboxProps } from './Lightbox.types';
import { i18n } from './Lightbox.i18n';

const LightboxBase = memo(
    forwardRef((props: LightboxProps, ref) => {
        const { files, isImago, onClose, downloadFileFn, index = 0 } = props;

        const intl = useIntl();

        const [fileDialogOpen, setFileDialogOpen] = useState(false);

        useImperativeHandle(
            ref,
            () => ({
                setFileDialog(value: boolean) {
                    setFileDialogOpen(value);
                },
            }),
            [],
        );

        const onCloseFn = useCallback(() => {
            setFileDialogOpen(false);
            onClose();
        }, [onClose]);

        const determineImageParameters = useCallback(
            (file: any) => {
                if (file.customSrc) {
                    return {
                        src: file.customSrc,
                        description: file.originalFilename,
                    };
                } else if (isImago) {
                    return {
                        src: `${baseApiUrl()}/integrations/imago/imago-file?&imagery=${
                            file.uid
                        }&imagetype=${file.imageTypeId}`,
                        description: `${file.startDepth ?? ''} - ${file.endDepth ?? ''}`,
                    };
                } else {
                    return {
                        src: `${baseApiUrl()}/${file.file}`,
                        description: file.originalFilename,
                    };
                }
            },
            [isImago],
        );

        const lightBoxImageList = React.useMemo(
            () =>
                files.map((file: FileObj) => ({
                    ...determineImageParameters(file),
                    download: !('fileObject' in file) && !isImago,
                })),
            [files, isImago, determineImageParameters, downloadFileFn],
        );

        return (
            <ReactLightbox
                {...{
                    ...(downloadFileFn && { download: { download: downloadFileFn } }),
                }}
                index={index}
                plugins={[Zoom, Thumbnails, Captions, Counter, Download]}
                zoom={{ maxZoomPixelRatio: 2 }}
                open={fileDialogOpen}
                slides={lightBoxImageList}
                close={onCloseFn}
                counter={{ separator: intl.formatMessage(i18n.seperator) }}
                carousel={{ finite: lightBoxImageList.length === 1 }}
                styles={{
                    container: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        fontWeight: 400,
                        letterSpacing: '0.1px',
                    },
                }}
            />
        );
    }),
);

export const Lightbox = memo((props: LightboxProps) => {
    const [files, setFiles] = React.useState<any[]>([]);

    const modalStateRef = React.useRef<{
        setFileDialog: (value: boolean) => void;
    }>();

    React.useEffect(() => {
        setFiles(props.files ?? []);
        modalStateRef.current?.setFileDialog(true);
    }, [props.files]);

    const onCloseFn = React.useCallback(() => {
        modalStateRef.current?.setFileDialog(false);
        props.onClose();
    }, [props.onClose, files]);

    return <LightboxBase {...props} files={files} ref={modalStateRef} onClose={onCloseFn} />;
});
