import { createSelector } from 'reselect';

import { AsyncState, AsyncStateError, ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import {
    EventsObject,
    EventsSubState,
    Job,
    EventsState,
    PostsObject,
    PostsSubState,
    Option,
    ActionSubState,
} from '../types';

const eventsState = (state: Partial<ShimState>): EventsState => {
    const { events: evs = {} as EventsState } = state || {};
    return evs;
};

const jobsList = createSelector(eventsState, ({ jobs = {} }: EventsState): Job[] =>
    Object.values(jobs),
);

const eventsSubState = createSelector(
    eventsState,
    ({ eventsState }: EventsState): EventsSubState => eventsState,
);

const events = createSelector(eventsSubState, ({ items = {} }: EventsSubState): EventsObject[] =>
    Object.values(items),
);

const eventsCollection = createSelector(
    eventsSubState,
    ({ items = {} }: EventsSubState): Record<string, EventsObject> => items,
);

const eventCount = createSelector(eventsSubState, ({ count = 0 }: EventsSubState): number => count);

const isSearchActive = createSelector(
    eventsState,
    ({ searchTerm = '' }: EventsState): boolean => searchTerm !== '',
);

const postsState = createSelector(
    eventsState,
    ({ postsState }: EventsState): PostsSubState => postsState,
);

const posts = createSelector(postsState, ({ items = {} }: PostsSubState): PostsObject[] =>
    Object.values(items),
);

const postsCollection = createSelector(
    postsState,
    ({ items = {} }: PostsSubState): Record<string, PostsObject> => items,
);

const postsCount = createSelector(postsState, ({ count = 0 }: PostsSubState): number => count);
const getPostById = (id: string) =>
    createSelector(posts, (items): PostsObject | undefined => items.find((post) => post.id === id));

const postActionState = createSelector(
    postsState,
    ({ actionState }: PostsSubState): AsyncState => actionState,
);

const postActionStateError = createSelector(
    postActionState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const shouldReloadAfterAction = createSelector(
    postActionState,
    ({ shouldReload }: ActionSubState): boolean => shouldReload ?? false,
);

const linkedObjectItems = createSelector(
    postActionState,
    ({ linkedObjectItems }: ActionSubState): Option[] => linkedObjectItems ?? [],
);

const eventBaseState = createSelector(
    eventsState,
    ({ eventsState }: EventsState): EventsSubState => eventsState,
);

const eventActionState = createSelector(
    eventBaseState,
    ({ actionState }: EventsSubState): ActionSubState => actionState,
);

const shouldEventReloadAfterAction = createSelector(
    eventActionState,
    ({ shouldReload }: ActionSubState): boolean => shouldReload ?? false,
);

export const selectors = {
    eventsState,
    jobsList,
    isJobListPending: isPendingSelector(eventsState),
    isJobListComplete: isCompleteSelector(eventsState),
    isJobListFailed: isFailedSelector(eventsState),
    isSearchActive,
    isEventsPending: isPendingSelector(eventsSubState),
    isEventsComplete: isCompleteSelector(eventsSubState),
    isEventsFailed: isFailedSelector(eventsSubState),
    events,
    eventsCollection,
    eventCount,
    eventActionState,
    isPostsPending: isPendingSelector(postsState),
    isPostsComplete: isCompleteSelector(postsState),
    isPostsFailed: isFailedSelector(postsState),
    posts,
    postsCollection,
    postsCount,
    postActionStateError,
    shouldReloadAfterAction,
    linkedObjectItems,
    postActionState,
    getPostById,
    isPostActionPending: isPendingSelector(postActionState),
    isPostActionComplete: isCompleteSelector(postActionState),
    isPostActionFailed: isFailedSelector(postActionState),
    isEventActionPending: isPendingSelector(eventActionState),
    isEventActionComplete: isCompleteSelector(eventActionState),
    isEventActionFailed: isFailedSelector(eventActionState),
    shouldEventReloadAfterAction,
};
