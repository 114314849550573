import { createSelector } from 'reselect';
import { AsyncState, ShimState } from 'state-domains/types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';

import {
    DownholeCollectionItem,
    ImagoContextState,
    ImagoIntegration,
    IntegrationState,
} from '../types';

const integrationState = (state: Partial<ShimState>): IntegrationState => {
    const { integrations: dhIntegrations = {} as IntegrationState } = state || {};
    return dhIntegrations;
};

const imagoState = createSelector(
    integrationState,
    ({ imago = {} as ImagoIntegration }): ImagoIntegration => imago,
);

const imagoConnectionState = createSelector(
    imagoState,
    ({ connectionState = {} as AsyncState }): AsyncState => connectionState,
);

const imagoConnectionStatus = createSelector(
    imagoState,
    ({ connectionStatus = '' }): string => connectionStatus,
);

const imagoImageriesCount = createSelector(
    imagoState,
    ({ imageriesCount = 0 }): number => imageriesCount,
);

const imagoSyncState = createSelector(
    imagoState,
    ({ syncState = {} as AsyncState }): AsyncState => syncState,
);

const imagoUserContextState = createSelector(
    imagoState,
    ({ userContextState = {} as ImagoContextState }): ImagoContextState => userContextState,
);

const imagoLoginState = createSelector(
    integrationState,
    ({ imago: { loginState = {} as AsyncState } }): AsyncState => loginState,
);

const isEvoOrgSet = createSelector(
    integrationState,
    ({ evoState: { orgState } }): boolean => !!orgState?.orgId,
);

const evoOrgState = createSelector(
    integrationState,
    ({ evoState: { orgState = {} as AsyncState } }): AsyncState => orgState,
);

const evoConfigurationState = createSelector(
    integrationState,
    ({ evoState: { configurationState = {} as AsyncState } }): AsyncState => configurationState,
);

const evoWorkspaceMap = createSelector(
    integrationState,
    ({ evoState: { configurationState } }): Record<string, any> => configurationState?.projectsMap,
);

const downholeCollectionState = createSelector(
    integrationState,
    ({ evoState: { downholeState = {} as AsyncState } }): AsyncState => downholeState,
);

const downholeCollections = createSelector(
    integrationState,
    ({ evoState: { downholeState } }): DownholeCollectionItem[] =>
        downholeState.downholeCollections,
);

const checkPermissionState = createSelector(
    integrationState,
    ({ evoState: { checkPermission = {} as AsyncState } }): AsyncState => checkPermission,
);

const hasPermission = createSelector(
    integrationState,
    ({ evoState: { checkPermission = {} as AsyncState & { hasPermission: boolean } } }): boolean =>
        checkPermission.hasPermission,
);

export const selectors = {
    integrationState,
    imagoState,
    imagoConnectionState,
    imagoConnectionStatus,
    isImagoConnectionStatusPending: isPendingSelector(imagoConnectionState),
    isImagoConnectionStatusComplete: isCompleteSelector(imagoConnectionState),
    isImagoConnectionStatusFailed: isFailedSelector(imagoConnectionState),
    imagoImageriesCount,
    imagoSyncState,
    isImagoSyncPending: isPendingSelector(imagoSyncState),
    isImagoSyncComplete: isCompleteSelector(imagoSyncState),
    isImagoSyncFailure: isFailedSelector(imagoSyncState),
    imagoUserContextState,
    isImagoUserContextPending: isPendingSelector(imagoUserContextState),
    isImagoUserContextComplete: isCompleteSelector(imagoUserContextState),
    isImagoUserContextFailed: isFailedSelector(imagoUserContextState),
    imagoLoginState,
    isImagoLoginPending: isPendingSelector(imagoLoginState),
    isImagoLoginComplete: isCompleteSelector(imagoLoginState),
    isImagoLoginFailure: isFailedSelector(imagoLoginState),
    isEvoOrgSet,
    evoOrgState,
    isEvoOrgPending: isPendingSelector(evoOrgState),
    evoConfigurationState,
    isEvoConfigurationStatePending: isPendingSelector(evoConfigurationState),
    isEvoConfigurationStateComplete: isCompleteSelector(evoConfigurationState),
    isEvoConfigurationStateFailed: isFailedSelector(evoConfigurationState),
    evoWorkspaceMap,
    downholeCollectionState,
    isDownholeCollectionStatePending: isPendingSelector(downholeCollectionState),
    isDownholeCollectionStateComplete: isCompleteSelector(downholeCollectionState),
    isDownholeCollectionStateFailed: isFailedSelector(downholeCollectionState),
    downholeCollections,
    checkPermissionState,
    isCheckPermissionStatePending: isPendingSelector(checkPermissionState),
    isCheckPermissionStateComplete: isCompleteSelector(checkPermissionState),
    isCheckPermissionStateFailed: isFailedSelector(checkPermissionState),
    hasPermission,
};
