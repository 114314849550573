import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { sendFileInBodyUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const uploadFilesForImport = (url: string, file: File): Observable<any> => {
    return sendFileInBodyUploadRequestWithXSRFTokenCatchErrors(
        url,
        { file },
        { method: 'PUT' },
        (response) => response.text(),
    ).pipe(switchMap((result: any) => observableOf(result)));
};
