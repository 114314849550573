export const i18n: Record<string, any> = {
    newPost: {
        id: 'notification.newPost',
        defaultMessage: 'A New Post Has Been Shared With You',
    },
    taggedPost: {
        id: 'notification.taggedPost',
        defaultMessage: 'You Have Been Tagged in This Post',
    },
    updatedPost: {
        id: 'notification.updatedPost',
        defaultMessage: 'This Post Has Been Updated',
    },
    taggedEvent: {
        id: 'notification.taggedEvent',
        defaultMessage: 'You Have Been Tagged in This Event',
    },
    replyPost: {
        id: 'notification.replyPost',
        defaultMessage: 'A Reply Has Been Added to This Post',
    },
    replyEvent: {
        id: 'notification.replyEvent',
        defaultMessage: 'A Reply Has Been Added to This Event',
    },
};
