import { PaginatedList } from '@local/web-design-system';
import { Skeleton, ThemeProvider } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { sliderStyle } from '../../ContentLoading.styles';

import { useStyles } from './ListSkeleton.styles';

function ListSkeletonContent() {
    const { classes } = useStyles();
    return Array(100)
        .fill(true)
        .map((_, idx) => (
            <tr key={uuidv4()}>
                <td style={{ padding: '0px' }} aria-label="Skeleton row">
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className={classes.projectListItem}
                        sx={{ backgroundColor: `${idx % 2 === 0 ? '#F9F9F9' : '#FFFFFF'}` }} // Alternates row colour
                    />
                </td>
            </tr>
        ));
}

export function ListLoadingContent() {
    const { classes } = useStyles();
    return (
        <PaginatedList
            isError={false}
            header={
                <ThemeProvider theme={sliderStyle}>
                    <thead>
                        <tr>
                            <td aria-label="Skeleton row">
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    className={classes.projectHeader}
                                />
                            </td>
                        </tr>
                    </thead>
                </ThemeProvider>
            }
            stickyHeader
            content={ListSkeletonContent()}
        />
    );
}
