import { createSelector } from 'reselect';
import { ShimState } from 'state-domains/types';

import { SnackbarNotification, SnackbarNotificationState } from '../types';

const snackbarState = (state: Partial<ShimState>): SnackbarNotificationState => {
    const { snackbarNotifications = {} as SnackbarNotificationState } = state || {};
    return snackbarNotifications;
};

const snackbarNotificationCollection = createSelector(
    snackbarState,
    ({ notificationList = {} as Record<string, SnackbarNotification> }) => notificationList,
);

const snackbarNotificationArray = createSelector(
    snackbarNotificationCollection,
    (notifications: Record<string, SnackbarNotification>) => Object.values(notifications),
);

const selectedRowCol = createSelector(
    snackbarState,
    ({ selectedRowCol = null }: SnackbarNotificationState) => selectedRowCol,
);

export const selectors = {
    snackbarNotificationCollection,
    snackbarNotificationArray,
    selectedRowCol,
};
