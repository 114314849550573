import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';
import { buildUpdatePostUrl } from 'state-domains/network/urls';

import { sendRequestWithXSRFToken } from '../../../utils';
import { UpdatePostPayload, EventsObject } from '../types';

export const updatePost = (payload: UpdatePostPayload): Observable<{ event: EventsObject }> => {
    const url = buildUpdatePostUrl();
    const payloadData = {
        ...convertToSnake(payload),
        ...(!!payload?.linkedObject && {
            linked_object: {
                ...convertToSnake(payload).linked_object,
                objectName: payload?.linkedObject?.objectName,
            },
        }),
    };
    delete payloadData?.linked_object?.object_name;
    return sendRequestWithXSRFToken(url, payloadData, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<{ event: EventsObject }>) => {
            const converted = convertToCamel<{ event: EventsObject }>(response);
            return observableOf(converted);
        }),
    );
};
