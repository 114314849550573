import { Download, Delete, FilePresent, OpenInNew, Edit } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { baseApiUrl } from 'state-domains/network';
import { imageContentTypes } from 'state-domains/utils/file-type-from-content-type';

import { ImageIconProps } from '../FileUpload.types';

import { useStyles } from './ImageIcon.styles';

export const ImageIcon = (props: ImageIconProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { classes } = useStyles();
    let imageIconComponent;
    const {
        readOnly = false,
        contentType,
        isImago,
        isDownloadable = true,
        widthParam = null,
        customSrc,
        openEdit,
        isMulti = false,
    } = props;
    const imageParentRef = useRef<HTMLDivElement>(null);
    const [imageParentHeight, setImageParentHeight] = useState(0);
    const hasImageContentType = imageContentTypes.has(contentType);
    const apiSrc = `${baseApiUrl()}/${props.src}`;
    const imgDimension = isMulti ? '51px' : '64px';

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === imageParentRef.current) {
                    setImageParentHeight(entry.contentRect.height);
                }
            }
        });

        if (imageParentRef.current) {
            resizeObserver.observe(imageParentRef.current);
        }

        return () => {
            if (imageParentRef.current) {
                resizeObserver.unobserve(imageParentRef.current);
            }
        };
    }, []);
    if (hasImageContentType) {
        imageIconComponent = (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    ...(isMulti && { position: 'absolute', top: '3px', left: '3px' }),
                    ...(!widthParam && { width: imgDimension, height: imgDimension }),
                }}
            >
                <img
                    style={{
                        width: 'auto',
                        height: 'auto',
                        ...(widthParam && { maxWidth: '100%', maxHeight: imageParentHeight }),
                        ...(!widthParam && { maxWidth: imgDimension, maxHeight: imgDimension }),
                    }}
                    src={!customSrc ? apiSrc : customSrc}
                />
            </Grid>
        );
    } else {
        // General file icon
        imageIconComponent = (
            <FilePresent
                fontSize="large"
                automation-id="FilePresentIcon"
                style={{
                    marginLeft: isMulti ? '4px' : '0',
                    marginTop: isMulti ? '3px' : '0',
                    ...(isMulti && {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        ...(!widthParam && { width: imgDimension, height: imgDimension }),
                    }),
                }}
            />
        );
    }

    const multiImageIconComponent = (
        <div style={{ float: 'left', position: 'relative' }}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    ...(!widthParam && { width: '64px', height: '64px' }),
                    backgroundImage: 'url("/images/Stack64.svg")',
                    backgroundSize: 'cover',
                }}
            >
                {imageIconComponent}
            </Grid>
        </div>
    );

    const onMouseOver = React.useCallback(() => setIsHovered(true), []);
    const onMouseLeave = React.useCallback(() => setIsHovered(false), []);
    const downloadFile = React.useCallback(
        () => props.downloadFile(props.index),
        [props.index, props.downloadFile],
    );
    const showLightBox = React.useCallback(
        () => props.showLightBox(props.index),
        [props.index, props.showLightBox],
    );
    const viewInImago = React.useCallback(
        () => props.viewInImago(props.index),
        [props.index, props.viewInImago],
    );
    const deleteFile = React.useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            props.deleteFile(props.index);
        },
        [props.index, props.deleteFile],
    );

    const rootClassName = classNames(classes.root, {
        [widthParam]: !!widthParam,
    });

    const iconClassName = classNames(classes.imageComponent, {
        [widthParam]: !!widthParam,
    });

    return (
        <Grid
            container
            className={rootClassName}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            ref={imageParentRef}
        >
            <Grid
                automation-id={`file-view-${props.index}`}
                container
                onClick={showLightBox}
                className={`${iconClassName}  ${
                    isHovered ? classes.imageComponentHover : ''
                } ${classes.cursorStyling}`}
            >
                {isMulti ? multiImageIconComponent : imageIconComponent}
            </Grid>
            <Grid
                automation-id={`file-${props.index}`}
                container
                className={widthParam ? classes.iconsRelativeStyling : classes.iconsComponent}
                sx={{
                    position: 'relative',
                    width: !widthParam ? (isImago || readOnly ? '28px' : '64px') : {},
                }}
            >
                {isHovered && (
                    <>
                        {!!openEdit && (
                            <>
                                <IconButton
                                    automation-id={`file-edit-${props.index}`}
                                    aria-label="edit"
                                    size="small"
                                    onClick={openEdit}
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                >
                                    <Edit fontSize="inherit" />
                                </IconButton>
                                <Grid width={8} />
                            </>
                        )}
                        {!readOnly && !isImago && (
                            <>
                                <IconButton
                                    automation-id={`file-delete-${props.index}`}
                                    aria-label="delete"
                                    size="small"
                                    color="warning"
                                    onClick={deleteFile}
                                    sx={{ position: 'absolute', top: 0, left: 0 }}
                                >
                                    <Delete fontSize="inherit" />
                                </IconButton>
                                <Grid width={8} />
                            </>
                        )}
                        {!isImago && isDownloadable && (
                            <IconButton
                                automation-id={`file-download-${props.index}`}
                                aria-label="download"
                                size="small"
                                onClick={downloadFile}
                                sx={{ position: 'absolute', top: 0, right: 0 }}
                            >
                                <Download fontSize="inherit" />
                            </IconButton>
                        )}
                        {isImago && (
                            <IconButton
                                automation-id={`file-view-in-Imago-${props.index}`}
                                aria-label="View in Imago"
                                size="small"
                                onClick={viewInImago}
                                sx={{ position: 'absolute', top: 0, right: 0 }}
                            >
                                <OpenInNew fontSize="inherit" />
                            </IconButton>
                        )}
                    </>
                )}
            </Grid>
            {props.name && (
                <Grid item container className={classes.textComponent}>
                    <Tooltip title={props.name}>
                        <Typography automation-id="typographyName" noWrap variant="body2">
                            {props.name}
                        </Typography>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
};
