import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { GridEntry, GridPreviewResponse } from 'state-domains/domain';
import { buildGridPreview } from 'state-domains/network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from 'state-domains/utils';

export const gridPreview = (
    payload: Record<string, GridEntry>,
): Observable<{ PreviewGrid: GridPreviewResponse }> => {
    const url = buildGridPreview();
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<{ PreviewGrid: GridPreviewResponse }>) =>
            observableOf(
                convertToCamel<{ PreviewGrid: GridPreviewResponse }>(response.PreviewGrid),
            ),
        ),
    );
};
