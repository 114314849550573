import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        justifyContent: 'flex-end',
        width: theme.spacing(8),
    },
    imageComponent: {
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(8),
        overflow: 'hidden',
    },
    cursorStyling: {
        cursor: 'pointer',
    },
    imageComponentHover: {
        opacity: 0.75,
    },
    iconsComponent: {
        marginTop: theme.spacing(-8),
        height: '28px',
        zIndex: 2,
    },
    iconsRelativeStyling: {
        position: 'relative',
        top: '-100%',
        zIndex: 2,
    },
    textComponent: {
        textAlign: 'center',
        marginTop: theme.spacing(0.5),
    },
}));
