import { checkEvoPermissions } from './checkEvoPermissions';
import { evoConfiguration } from './evoConfiguration';
import { imagoCheckConnection } from './imagoCheckConnection';
import { imagoDisconnect } from './imagoDisconnect';
import { imagoLogin } from './imagoLogin';
import { imagoSync } from './imagoSync';
import { imagoUserContext } from './imagoUserContext';
import { loadDownholeCollections } from './loadDownholeCollections';
import { loadEvoOrg } from './loadEvoOrg';
import { loadEvoWorkspaces } from './loadEvoWorkspaces';

export const integrationShim = {
    imagoLogin,
    imagoCheckConnection,
    imagoDisconnect,
    imagoSync,
    imagoUserContext,
    evoConfiguration,
    loadEvoOrg,
    loadEvoWorkspaces,
    loadDownholeCollections,
    checkEvoPermissions,
};
