import { importCollars } from './importCollars';
import { loadProjectOverviewCollarsCount, loadProjectOverviewCollars } from './loadOverviewCollars';
import { loadProject } from './loadProject';
import { loadProjectFileGroups } from './loadProjectFileGroups';
import { loadProjectList } from './loadProjectList';
import { setPinProject } from './pinProject';
import { uploadFilesForImport } from './uploadFilesForImport';

export const projectShim = {
    loadProject,
    loadProjectFileGroups,
    loadProjectList,
    setPinProject,
    loadProjectOverviewCollarsCount,
    loadProjectOverviewCollars,
    importCollars,
    uploadFilesForImport,
};
