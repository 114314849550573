import { CloseIcon, fonts, theme } from '@local/web-design-system';
import { CardHeader, IconButton } from '@mui/material';
import { mainFontTypes } from 'src/styles';
import React from 'react';

export function DialogHeader(
    props: Readonly<{ title: string; onClick: React.MouseEventHandler<HTMLButtonElement> }>,
) {
    return (
        <CardHeader
            title={props.title}
            sx={{
                background: theme.palette.primary.main,
                height: theme.spacing(3),
                '& .MuiCardHeader-title': {
                    ...mainFontTypes,
                    fontWeight: fonts.weights.medium,
                    fontSize: theme.spacing(2.25),
                    color: theme.palette.common.white,
                    whiteSpace: 'pre-wrap',
                },
                '& .MuiCardHeader-subheader': {
                    color: theme.palette.common.white,
                    opacity: '0.7',
                    fontSize: '11px',
                    fontWeight: 'normal',
                },
                '& .MuiCardHeader-action': {
                    marginRight: '0px',
                    marginTop: '0px',
                },
            }}
            action={
                <IconButton
                    onClick={props.onClick}
                    color="inherit"
                    size="small"
                    style={{
                        padding: theme.spacing(1.5),
                        marginTop: theme.spacing(-2.5),
                        marginRight: theme.spacing(-2),
                    }}
                >
                    <CloseIcon sx={{ fontSize: theme.spacing(1.25), color: 'white' }} />
                </IconButton>
            }
        />
    );
}
