import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import { CLIENT_SIDE_PAGINATION_LIMIT, DATATABLE_TYPE } from 'state-domains/constants';
import {
    convertToBackendSortKey,
    downloadFile,
    getConfigurationSortOptionsFromCache,
} from 'state-domains/domain/utils';
import { getSessionId } from 'src/utilities';
import { getCachedDataTablePage } from 'src/components/PaginationControls/PaginationControls.utils';
import { DataRowType, GetState } from 'state-domains/types';
import { getFiltersForConfiguration } from 'src/components/Configuration/Filter/Filter.utils';
import { dispatchUpdateSelectClear } from 'state-domains/domain/subscription/state/actions';
import { UserPortalState } from 'src/state';
import { from as observableFrom, mergeMap, toArray } from 'rxjs';
import { getState } from 'state-domains/store';
import { PostLinkedObjectType } from 'state-domains/domain/events';
import { TimeType } from 'src/utilities/types';

import {
    ADD_EVENT_REPLY,
    ADD_POST_ATTACHMENTS,
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_EVENT_POST_ACTION_STATES,
    CLEAR_EVENTS,
    CLEAR_JOB_LIST,
    CLEAR_POST_ACTION,
    CLEAR_POSTS,
    CREATE_OR_UPDATE_JOB,
    CREATE_POST,
    DELETE_POSTS,
    DOWNLOAD_POST_ATTACHMENT,
    LIST_JOBS,
    LOAD_EVENTS,
    LOAD_LINKED_OBJECTS,
    LOAD_POSTS,
    SET_JOB_SEARCH_TERM,
    UPDATE_EVENT_POSTS,
    UPDATE_POST,
} from '../../../types/actionTypes';
import {
    convertToCamel,
    isValidSecureUrl,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { eventsShim } from '../shim';
import {
    AttachFileToPostResponse,
    CreatePostPayload,
    EventNotificationTypes,
    EventsObject,
    EventType,
    Job,
    JobCompleteEvent,
    JobType,
    PostsObject,
    UpdatePostPayload,
} from '../types';
import { generateSnackBarNotifications } from '../utils';
import { isActionValidAtLocation } from '../../utils';

const loadJobList =
    (userId?: string, statuses = ['all']) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(LIST_JOBS) });
        eventsShim.loadJobList(statuses, userId).subscribe({
            next: (jobsList: Job[]) =>
                dispatch({
                    type: typeComplete(LIST_JOBS),
                    payload: jobsList,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LIST_JOBS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadHelper = (
    type: DATATABLE_TYPE,
    searchTerm: string,
    getState: GetState,
    field = 'name',
) => {
    const userId = getState().user?.id ?? '';
    const subscriptionId = getState().user?.selected?.id ?? '';
    const filters = getFiltersForConfiguration(type, userId, subscriptionId);
    if (searchTerm) {
        filters.push({
            type: 'text',
            operator: 'contains',
            values: [searchTerm],
            field,
        });
    }
    const { limit, offset } = getCachedDataTablePage(type, userId, subscriptionId ?? '');
    const sortBy = convertToBackendSortKey(
        getConfigurationSortOptionsFromCache(userId, subscriptionId ?? '', type),
    );
    return { filters, limit, offset, sortBy };
};

const loadEvents =
    (
        searchTerm = '',
        queryObj?: { filters: any[]; limit: number; offset: number; sortBy: string },
        configType = DATATABLE_TYPE.EVENTS,
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(LOAD_EVENTS) });
        const { filters, limit, offset, sortBy } =
            queryObj ?? loadHelper(configType, searchTerm, getState, 'reference_name');
        eventsShim.loadEvents(filters, limit, offset, sortBy).subscribe({
            next: (events: { Event: EventsObject[]; count: number }) =>
                dispatch({
                    type: typeComplete(LOAD_EVENTS),
                    payload: events,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_EVENTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearEvents = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_EVENTS) });

const loadPosts =
    (
        searchTerm = '',
        queryObj?: { filters: any[]; limit: number; offset: number; sortBy: string },
        configType = DATATABLE_TYPE.POSTS,
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(LOAD_POSTS) });
        const { filters, limit, offset, sortBy } =
            queryObj ?? loadHelper(configType, searchTerm, getState, 'content');
        eventsShim.loadPosts(filters, limit, offset, sortBy).subscribe({
            next: (posts: { Post: PostsObject[]; count: number }) =>
                dispatch({
                    type: typeComplete(LOAD_POSTS),
                    payload: posts,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_POSTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearPosts = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_POSTS) });

const clearJobList = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_JOB_LIST) });

const setJobSearchTerm =
    (searchTerm: string, offset = 0, limit: number = CLIENT_SIDE_PAGINATION_LIMIT) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: typeComplete(SET_JOB_SEARCH_TERM),
            payload: { searchTerm, offset, limit },
        });

const updateJob = (data: string) => (dispatch: Dispatch) => {
    const payload = convertToCamel<Job>(JSON.parse(data));
    dispatch({ type: CREATE_OR_UPDATE_JOB, payload });
};

const completeJob = (data: string) => {
    const event = convertToCamel<JobCompleteEvent>(JSON.parse(data));
    if (
        event.detail === JobType.Export ||
        event.detail === JobType.ListExport ||
        event.detail === JobType.TableExport
    ) {
        const sessionId = getSessionId();

        if (isValidSecureUrl(event.referenceDetail) && event.detail1 === sessionId) {
            downloadFile(event.referenceDetail);
        }
    }
};

const createPost = (payload: CreatePostPayload) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(CREATE_POST) });
    const newAttachments = payload.newAttachments;
    delete payload.newAttachments;
    return eventsShim.createPost(payload).subscribe({
        next: (response: { event: EventsObject }) => {
            dispatch({
                type: typeComplete(CREATE_POST),
                payload: { ...response, isFinalized: (newAttachments?.length ?? 0) === 0 },
            });
            if (newAttachments?.length) {
                addPostAttachments(response.event.post.id, newAttachments, 'new_event')(dispatch);
            } else {
                eventsShim.notify({ id: response.event.id, type: 'new_event' }).subscribe();
            }
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(CREATE_POST),
                payload: { error },
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const addPostAttachments =
    (postId: string, payload: File[], notifyType = '') =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_POST_ATTACHMENTS) });
        return observableFrom(payload)
            .pipe(mergeMap((file: File) => eventsShim.attachFileToPost(postId, file), 5))
            .pipe(toArray())
            .subscribe({
                next: (attachments: { file: AttachFileToPostResponse; name: string }[]) => {
                    const error: string[] = [];
                    for (const attachment of attachments) {
                        if ((attachment.file as any).ok === false) {
                            error.push(`${attachment.name} could not be uploaded.`);
                        }
                    }

                    if (error.length) {
                        dispatch({
                            type: ADD_SNACKBAR_NOTIFICATION,
                            payload: {
                                type: NotificationType.ERROR,
                                message: { message: error.join('\n') },
                            },
                        });
                    }

                    dispatch({
                        type: typeComplete(ADD_POST_ATTACHMENTS),
                        payload: {
                            attachments: attachments.filter((x) => x.file.file),
                            postId,
                        },
                    });

                    if (notifyType) {
                        eventsShim.notify({ id: postId, type: notifyType }).subscribe();
                    }
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(ADD_POST_ATTACHMENTS), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const deletePost = (id: string) => (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: typePending(DELETE_POSTS) });
    eventsShim.deletePosts(id).subscribe({
        next: () => {
            dispatch({ type: typeComplete(DELETE_POSTS) });
            dispatchUpdateSelectClear(
                [{ id }],
                getState() as UserPortalState,
                dispatch,
                true,
                TimeType.SECONDS,
            );
            eventsShim.notify({ id, type: 'deleted-post' }).subscribe();
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(DELETE_POSTS), payload: { error } });
        },
    });
};

const clearPostAction = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_POST_ACTION });
};

const clearEventPostActionStates = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_EVENT_POST_ACTION_STATES });
};

const downloadPostAttachment =
    (postId: string, attachmentId: string, attachmentName: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(DOWNLOAD_POST_ATTACHMENT) });
        eventsShim.downloadPostAttachment(postId, attachmentId).subscribe({
            next: (blob) => {
                const url = window.URL.createObjectURL(blob);
                downloadFile(url, attachmentName);
                dispatch({ type: typeComplete(DOWNLOAD_POST_ATTACHMENT) });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(DOWNLOAD_POST_ATTACHMENT), payload: { error } });
            },
        });
    };

const addEventReply =
    (eventId: string, reply: string, eventType: EventType, customReply?: Function) =>
    (dispatch: Dispatch, getState: GetState) => {
        customReply
            ? customReply(true)
            : dispatch({ type: typePending(ADD_EVENT_REPLY), payload: { eventType } });
        const allState = getState() as UserPortalState;
        return eventsShim.addEventReply({ event: eventId, reply: reply.trim() }).subscribe({
            next: (response: { event: EventsObject }) => {
                if (customReply) {
                    customReply(false, response.event);
                }

                const state = getState();
                const eventExists = !!state.events?.eventsState?.items?.[response.event.id];
                const postExists = !!state.events?.postsState?.items?.[response.event.id];

                if (eventExists || postExists) {
                    dispatch({
                        type: typeComplete(ADD_EVENT_REPLY),
                        payload: { ...response, eventType },
                    });
                }

                dispatchUpdateSelectClear(
                    [
                        eventType === EventType.EVENT ? response.event : response.event.post,
                    ] as unknown as DataRowType[],
                    allState,
                    dispatch,
                    false,
                    TimeType.SECONDS,
                );

                eventsShim.notify({ id: eventId, type: 'new_reply' }).subscribe();
            },
            error: (error: any) => {
                if (customReply) {
                    customReply(false, null, error);
                } else {
                    dispatch({ type: typeFail(ADD_EVENT_REPLY), payload: { error, eventType } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                }
            },
        });
    };

const loadLinkedObjects =
    (type: PostLinkedObjectType, projectId: string, activityId: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(LOAD_LINKED_OBJECTS) });
        return eventsShim.loadLinkedObjects(type, projectId, activityId).subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(LOAD_LINKED_OBJECTS),
                    payload: response,
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_LINKED_OBJECTS), payload: { error } });
            },
        });
    };
const updatePost = (payload: UpdatePostPayload) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(UPDATE_POST) });
    const newAttachments = payload.newAttachments;
    delete payload.newAttachments;
    const allState = getState() as UserPortalState;
    eventsShim.updatePost(payload).subscribe({
        next: (response) => {
            dispatch({
                type: typeComplete(UPDATE_POST),
                payload: { ...response, isFinalized: (newAttachments?.length ?? 0) === 0 },
            });
            dispatchUpdateSelectClear(
                [response.event.post] as unknown as DataRowType[],
                allState,
                dispatch,
                false,
                TimeType.SECONDS,
            );
            if (newAttachments?.length) {
                addPostAttachments(payload.id, newAttachments, 'updated-post')(dispatch);
            } else {
                eventsShim.notify({ id: payload.id, type: 'updated-post' }).subscribe();
            }
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(UPDATE_POST), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const modifyEventPosts =
    (item: string, senderId: string, notificationType: string, pathName: string) =>
    (dispatch: Dispatch, getState: GetState) => {
        const event = convertToCamel<EventsObject>(JSON.parse(item));
        const state = getState();
        const currentUserId = state.user?.id ?? '';
        const users = state.subscription?.users ?? {};

        if (isActionValidAtLocation(pathName, notificationType)) {
            dispatch({
                type: UPDATE_EVENT_POSTS,
                payload: { event, notificationType },
            });

            if (event?.id) {
                dispatchUpdateSelectClear(
                    [
                        event.eventType === EventType.POST ? event.post : event,
                    ] as unknown as DataRowType[],
                    state as UserPortalState,
                    dispatch,
                    false,
                    TimeType.SECONDS,
                );
            }
        }

        generateSnackBarNotifications(
            event,
            notificationType as EventNotificationTypes,
            senderId,
            currentUserId,
            users,
        ).forEach((x) =>
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: x,
            }),
        );
    };

export const actions = {
    loadJobList,
    loadEvents,
    clearEvents,
    loadPosts,
    clearPosts,
    updateJob,
    clearJobList,
    setJobSearchTerm,
    completeJob,
    createPost,
    addPostAttachments,
    deletePost,
    clearPostAction,
    clearEventPostActionStates,
    downloadPostAttachment,
    addEventReply,
    loadLinkedObjects,
    updatePost,
    modifyEventPosts,
};
