import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { evoDownholeCollectionsUrl } from 'state-domains/network';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken } from '../../../utils';
import { DownholeCollectionPayload, EvoOrgState } from '../types';

export const loadDownholeCollections = (
    payload: DownholeCollectionPayload,
    workspaceId: string,
): Observable<EvoOrgState> => {
    const obs = sendRequestWithXSRFToken(
        evoDownholeCollectionsUrl(workspaceId),
        convertToSnake(payload),
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const workspaces = convertToCamel<any>(response);
            return observableOf(workspaces);
        }),
    );
};
