import { v4 as uuidv4 } from 'uuid';
import { getErrorFromState } from 'state-domains/domain/utils';
import { BaseAction, Maybe } from 'state-domains/types';
import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_SNACKBAR_NOTIFICATIONS,
    DELETE_SNACKBAR_NOTIFICATION,
    REFRESH_STATE,
    SELECT_ROW_COL_IN_GRID,
} from 'state-domains/types/actionTypes';
import { mappedReducer } from 'state-domains/utils';

import {
    SnackbarNotification,
    SnackbarNotificationDescriptor,
    SnackbarNotificationPayload,
    SnackbarNotificationState,
} from '../types';

export const INITIAL_STATE: SnackbarNotificationState = {
    notificationList: {},
    selectedRowCol: null,
};

const addSnackbarMessage = (
    state: SnackbarNotificationState,
    action: BaseAction<SnackbarNotificationPayload>,
): SnackbarNotificationState => {
    const {
        type,
        message,
        modalData,
        i18nKey: originalI18nKey,
        i18nValues = {},
        descriptor = uuidv4(),
    } = action.payload;
    const { notificationList } = state;

    const { error, i18nKey } = getErrorFromState(message);

    if (!originalI18nKey && !error?.message) {
        return state;
    }

    const notificationMessage = {
        descriptor,
        type,
        i18nKey: i18nKey && !originalI18nKey ? error?.message : originalI18nKey,
        i18nValues,
        message: error?.message,
        modalData,
    } as SnackbarNotification;

    const newNotificationList = { ...notificationList };

    newNotificationList[descriptor] = notificationMessage;

    return {
        ...state,
        notificationList: newNotificationList,
    };
};

const deleteSnackbarMessage = (
    state: SnackbarNotificationState,
    action: BaseAction<SnackbarNotificationDescriptor>,
): SnackbarNotificationState => {
    const { payload: descriptor } = action;
    const { notificationList } = state;

    if (!descriptor) {
        return state;
    }

    const newNotificationList = { ...notificationList };

    delete newNotificationList[descriptor];

    return {
        ...state,
        notificationList: newNotificationList,
    };
};

const clearSnackbarMessages = (
    state: SnackbarNotificationState,
    _action: BaseAction<Maybe<SnackbarNotificationDescriptor[]>>,
): SnackbarNotificationState => ({
    ...state,
    notificationList: {},
});

const resetSnackbarState = (_state: SnackbarNotificationState, _action: BaseAction) => ({
    ...INITIAL_STATE,
});

const selectRowColInGrid = (state: SnackbarNotificationState, action: BaseAction) => ({
    ...state,
    selectedRowCol: action.payload,
});

export const reducer = mappedReducer(INITIAL_STATE, {
    [ADD_SNACKBAR_NOTIFICATION]: addSnackbarMessage,
    [DELETE_SNACKBAR_NOTIFICATION]: deleteSnackbarMessage,
    [CLEAR_SNACKBAR_NOTIFICATIONS]: clearSnackbarMessages,
    [SELECT_ROW_COL_IN_GRID]: selectRowColInGrid,
    [REFRESH_STATE]: resetSnackbarState,
});
