import { fonts, APPBAR_HEIGHT_UNITS, theme, mySeequentFonts } from '@local/web-design-system';
import { Theme } from '@mui/material';
import { CSSObject } from 'tss-react';

export const commonStyles = (theme: Theme) =>
    ({
        root: {
            display: 'flex',
            minHeight: '100vh',
            backgroundColor: theme.palette.grey[100],
        },
        content: {
            width: '100%',
            backgroundColor: theme.palette.grey[100],
            marginTop: theme.spacing(APPBAR_HEIGHT_UNITS),
            minWidth: 0,
        },
        contentWhite: {
            backgroundColor: theme.palette.common.white,
        },
        appContentAreaRoot: {
            backgroundColor: theme.palette.grey[100],
        },
        contentTitle: {
            ...fonts.h2,
            color: theme.palette.primary.main,
        },
        fullHeight: {
            height: '100%',
        },
        leftIcon: {
            marginRight: theme.spacing(),
        },
        row: {
            flexBasis: 0,
            flexGrow: 0,
        },
        rowGrow: {
            flexBasis: 0,
            flexGrow: 1,
            display: 'flex',
        },
        buttonIconText: {
            paddingLeft: theme.spacing(2),
        },
        emptyStateFullPage: {
            padding: `${theme.spacing(12.5)} 0 0 0`,
        },
        emptyStateTable: {
            padding: `${theme.spacing(2.5)} 0`,
        },
        emptyStateTableCell: {
            borderBottom: 0,
        },
        deleteSecondaryButton: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[50],
        },
        drawerOpen: {
            width: theme.spacing(25),
        },
        overlayDialog: {
            zIndex: 1,
            position: 'absolute',
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
        },
        overlayBackground: {
            backgroundColor: 'white',
            boxShadow: '0 0 6px 0 rgb(0 0 0 / 10%)',
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        helperText: {
            paddingTop: '2px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            lineHeight: '13px',
            color: theme.palette.error.main,
            ...fonts.errorMessage,
        },
        changeHover: {
            '&:hover': {
                backgroundColor: '#265C7F',
            },
        },
        primaryText: {
            wordBreak: 'break-word',
            paddingRight: theme.spacing(1),
            fontSize: '12px',
            textDecoration: 'none',
            display: 'flex',
            color: '#000',
        },
        secondaryText: {
            fontSize: '11px',
            color: '#8e9095',
            wordWrap: 'break-word',
        },
        emptyStateImg: {
            position: 'relative !important',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            overflow: 'hidden !important',
        } as unknown as CSSObject,
        emptyStateImgBody: {
            position: 'absolute !important',
            top: '55% !important',
            left: '50% !important',
            transform: 'translate(-50%, 0) !important',
            paddingLeft: '24px !important',
            width: '100% !important',
            maxWidth: '100% !important',
            flexBasis: '100% !important',
        } as unknown as CSSObject,
        emptyStateTitle: {
            fontSize: '14px !important',
            paddingBottom: '0px !important',
        } as unknown as CSSObject,
        emptyStateMessage: {
            fontSize: '11px !important',
            paddingTop: '2px !important',
        } as unknown as CSSObject,
        contentTitleUnderline: {
            position: 'relative',
            color: '#265C7F',
            fontFamily: 'Roboto',
            fontSize: '17px',
            letterSpacing: '0.2px',
            lineHeight: '20px',
            width: '100%',
            textAlign: 'left',
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: '400 !important',
        },
        paginationButton: {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.grey[700],
            borderRadius: 0,
            padding: 0,
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        typographyRequired: {
            '&::after': {
                content: "' *'",
                color: '#D0021B',
            },
        },
        notificationBorder: {
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        notificationMessage: {
            color: '#39393C',
            fontFamily: 'Roboto',
            fontSize: '11px',
            lineHeight: '15px',
        },
        chipStyles: {
            ...mySeequentFonts.chip,
            marginLeft: '8px',
            height: '18px',
            textTransform: 'uppercase' as const,
            color: 'white',
            letterSpacing: 'normal',
            lineHeight: '18px',
        },
        thinScrollBar: {
            scrollbarWidth: 'thin',
            overflowY: 'auto',
        },
    }) as const;
export const mainFontTypes = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    letterSpacing: '0.1px',
    lineHeight: '17px',
};
export const mainFonts = {
    ...mainFontTypes,
    color: '#1D1F23',
};
export const mainTextFonts = {
    ...mainFonts,
    fontWeight: fonts.weights.regular,
    fontSize: 14,
};
export const mainFieldStyle = {
    height: '40px',
    secondaryHeight: '48px',
    paddingLeft: '16px',
    fontSize: mainTextFonts.fontSize,
    letterSpacing: '0.1px',
    placeHolder: {
        color: 'rgba(0, 0, 0, 0.42)',
        fontStyle: 'italic',
        fontWeight: '400',
        fontSize: mainTextFonts.fontSize,
        lineHeight: '48px',
    },
    dropDown: {
        fontSize: '13px',
    },
};
export const alertDialogTitle = {
    fontWeight: `${fonts.weights.medium} !important`,
};

export const cardHeaderProperties = {
    background: theme.palette.primary.main,
    height: theme.spacing(7),
    '& .MuiCardHeader-title': {
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 'normal',
    },
    '& .MuiCardHeader-subheader': {
        color: theme.palette.common.white,
        opacity: '0.7',
        fontSize: '11px',
        fontWeight: 'normal',
    },
    '& .MuiCardHeader-action': {
        marginRight: '0px',
        marginTop: '0px',
    },
};

export const dataGridStyles = (padding = 1) => ({
    // #region ================================ AG Grid CSS Variables ================================
    '--ag-cell-horizontal-padding': theme.spacing(padding),
    '--ag-cell-widget-spacing': theme.spacing(padding),
    '--ag-header-background-color': theme.palette.grey[800],
    '--ag-header-foreground-color': theme.palette.common.white,
    '--ag-material-primary-color': theme.palette.primary.main,
    '--ag-material-accent-color': theme.palette.primary.main,
    '--ag-header-cell-hover-background-color': theme.palette.grey[500],
    '--ag-checkbox-checked-color': theme.palette.primary.main,
    '--ag-cell-horizontal-border': `1px solid ${theme.palette.grey[200]}`,
    '--ag-borders': `1px solid`,
    '--ag-border-color': theme.palette.grey[200],
    '--ag-header-column-separator-display': 'block',
    '--ag-header-column-separator-height': '100%',
    '--ag-header-column-separator-width': '1px',
    '--ag-header-column-separator-color': theme.palette.common.white,
    '--ag-font-family': '"Roboto", sans-serif',
    '--ag-header-cell-moving-background-color': theme.palette.grey[800],
    '--ag-font-size': '13px',
    // #endregion

    // #region ================================ Class Overrides ================================
    // Background color styling for side panel.
    '.ag-side-bar': {
        backgroundColor: `${theme.palette.grey[100]}`,
    },

    '.ag-cell': {
        paddingLeft: 'var(--ag-cell-horizontal-padding)',
        paddingRight: 'var(--ag-cell-horizontal-padding)',
    },

    '.ag-ltr .ag-row-drag': {
        marginRight: 0,
        width: '100%',
        justifyContent: 'center',
    },

    '.ag-filter-toolpanel-instance-filter': {
        marginTop: 0,
    },

    '.ag-header-cell-text': {
        display: '-webkit-box',
        whiteSpace: 'pre-wrap' as const,
        WebkitBoxOrient: 'vertical' as const,
        WebkitLineClamp: 2,
        textWrap: 'wrap' as const,
        wordWrap: 'break-word' as const,
        fontSize: 'var(--ag-font-size)',
    },

    '.ag-theme-material .ag-cell-inline-editing': {
        height: '100%',
    },

    '.ag-theme-material input[class^=ag-][type=text]': {
        paddingBottom: 0,
    },

    '.ag-side-buttons': {
        backgroundColor: `${theme.palette.grey[50]}`,
        // border: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: '10px',
    },

    // Need to override changes made by AG grid CSS variables to tool panel.
    '.ag-theme-material .ag-filter-toolpanel-header': {
        color: 'var(--ag-data-color)',
        opacity: 0.7,
    },
    // #endregion

    // #region ================================ Cell classes ================================
    '.limitedPadding': {
        padding: `0 ${theme.spacing(2)} 0 0`,
    },
    '.noHeaderPadding': {
        padding: 0,
    },
    // #endregion
});

export const spanChipStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#FFF',
    borderRadius: '16px',
    cursor: 'unset',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '3px',
    paddingBottom: '3px',
};

export const pAsTypograhpyFontStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    letterSpacing: '0.1px',
    margin: '0px',
};
