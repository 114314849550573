import { makeStyles } from 'tss-react/mui';
import { mainTextFonts } from 'src/styles/common.styles';

export const useStyles = makeStyles()(
    () =>
        ({
            checkboxLabel: {
                ...mainTextFonts,
            },
        }) as const,
);
