import {
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    CLIENT_SIDE_PAGINATION_LIMIT,
} from '../../../constants';
import { BaseAction } from '../../../types';
import {
    CLEAR_JOB_LIST,
    LIST_JOBS,
    REFRESH_STATE,
    SET_JOB_SEARCH_TERM,
    CREATE_OR_UPDATE_JOB,
    LOAD_EVENTS,
    LOAD_POSTS,
    DELETE_POSTS,
    DOWNLOAD_POST_ATTACHMENT,
    CLEAR_EVENTS,
    CLEAR_POSTS,
    ADD_EVENT_REPLY,
    LOAD_LINKED_OBJECTS,
    CREATE_POST,
    CLEAR_POST_ACTION,
    ADD_POST_ATTACHMENTS,
    UPDATE_POST,
    CLEAR_EVENT_POST_ACTION_STATES,
    UPDATE_EVENT_POSTS,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    staleReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import {
    EventsObject,
    Job,
    JobError,
    EventsState,
    EventType,
    PostsObject,
    EventNotificationTypes,
    FileObj,
} from '../types';

const INITIAL_DATA_STATE = {
    items: {},
    count: 0,
    status: LOAD_STATUS_PENDING,
    error: null,
    actionState: {
        status: LOAD_STATUS_STALE,
        error: null,
        linkedObjectItems: [],
    },
};

export const INITIAL_STATE: EventsState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    jobs: {},
    searchTerm: '',
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
    eventsState: INITIAL_DATA_STATE,
    postsState: INITIAL_DATA_STATE,
};

function setJobSearchTermReducer(
    state: EventsState,
    action: BaseAction<{ searchTerm: string; offset: number; limit: number }>,
): EventsState {
    const { searchTerm, offset, limit } = action.payload;
    return completeReducer({
        ...state,
        searchTerm,
        offset,
        limit,
    });
}

const createOrUpdateJob = (state: EventsState, action: BaseAction<Job>) => {
    const { id } = action.payload;
    if (!id) {
        return state;
    }
    const job = state.jobs[id] ?? {};
    return {
        ...state,
        jobs: {
            ...state.jobs,
            [id]: {
                ...job,
                ...action.payload,
            },
        },
    };
};

const listJobsPendingReducer = (state: EventsState): EventsState => pendingReducer({ ...state });

const listJobsSuccessReducer = (state: EventsState, actions: BaseAction) =>
    completeReducer({
        ...state,
        jobs: Object.fromEntries(actions.payload.map((job: Job) => [job.id, job])),
    });

function listJobsFailReducer(state: EventsState, action: BaseAction<JobError>) {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
}
const resetEventsStateReducer = (_state: EventsState, _action: BaseAction) => ({
    ...INITIAL_STATE,
});

const clearJobListReducer = (_state: EventsState, _action: BaseAction) => ({ ...INITIAL_STATE });

const listEventsPendingReducer = (state: EventsState): EventsState => ({
    ...state,
    eventsState: pendingReducer(state.eventsState),
});

const listEventsSuccessReducer = (state: EventsState, actions: BaseAction) => ({
    ...state,
    eventsState: completeReducer({
        ...state.eventsState,
        items: Object.fromEntries(actions.payload.Event.map((x: EventsObject) => [x.id, x])),
        count: actions.payload.count,
    }),
});

const listEventsFailReducer = (state: EventsState, action: BaseAction<JobError>): EventsState => ({
    ...state,
    postsState: failureReducer({ ...state.postsState, error: action.payload.error }),
});

const clearEventsReducer = (state: EventsState): EventsState => ({
    ...state,
    eventsState: INITIAL_DATA_STATE,
});

const listPostsPendingReducer = (state: EventsState): EventsState => ({
    ...state,
    postsState: pendingReducer(state.postsState),
});

const listPostsSuccessReducer = (state: EventsState, actions: BaseAction) => ({
    ...state,
    postsState: completeReducer({
        ...state.postsState,
        items: Object.fromEntries(actions.payload.Post.map((x: PostsObject) => [x.id, x])),
        count: actions.payload.count,
    }),
});

const listPostsFailReducer = (state: EventsState, action: BaseAction<JobError>): EventsState => ({
    ...state,
    postsState: failureReducer({ ...state.postsState, error: action.payload.error }),
});

const clearPostsReducer = (state: EventsState): EventsState => ({
    ...state,
    postsState: INITIAL_DATA_STATE,
});

const loadLinkedObjectsSuccessReducer = (state: EventsState, actions: BaseAction) => ({
    ...state,
    postsState: {
        ...state.postsState,
        actionState: completeReducer({ linkedObjectItems: actions.payload }),
    },
});

const updateEventSuccessReducer = (
    state: EventsState,
    actions: BaseAction<{ event: EventsObject; eventType: EventType }>,
) => {
    const { event, eventType } = actions.payload;

    const newEvents = { ...state.eventsState.items };
    newEvents[event.id] = event;

    const newPosts = { ...state.postsState.items };
    newPosts[event.post.id] = event.post;

    if (eventType === EventType.EVENT) {
        return {
            ...state,
            eventsState: {
                ...state.eventsState,
                items: newEvents,
                actionState: completeReducer({}),
            },
        };
    }

    return {
        ...state,
        postsState: {
            ...state.postsState,
            items: newPosts,
            actionState: completeReducer({}),
        },
    };
};

const updateEventPendingReducer = (
    state: EventsState,
    actions: BaseAction<{ eventType: EventType }>,
): EventsState => {
    const { eventType } = actions.payload;

    if (eventType === EventType.EVENT) {
        return {
            ...state,
            eventsState: {
                ...state.eventsState,
                actionState: pendingReducer({}),
            },
        };
    }

    return {
        ...state,
        postsState: {
            ...state.postsState,
            actionState: pendingReducer({}),
        },
    };
};

const updateEventFailReducer = (
    state: EventsState,
    action: BaseAction<{ error: JobError; eventType: EventType }>,
): EventsState => {
    const { error, eventType } = action.payload;

    if (eventType === EventType.EVENT) {
        return {
            ...state,
            eventsState: {
                ...state.eventsState,
                actionState: failureReducer({ ...state.eventsState.actionState, error }),
            },
        };
    }

    return {
        ...state,
        postsState: {
            ...state.postsState,
            actionState: failureReducer({ ...state.postsState.actionState, error }),
        },
    };
};

const postsActionStatePendingReducer = (state: EventsState): EventsState => ({
    ...state,
    postsState: { ...state.postsState, actionState: pendingReducer({}) },
});

const postsActionStateFailReducer = (state: EventsState, action: BaseAction): EventsState => ({
    ...state,
    postsState: {
        ...state.postsState,
        actionState: failureReducer({ error: action.payload.error }),
    },
});

const postsActionStateSuccessReducer = (state: EventsState): EventsState => ({
    ...state,
    postsState: { ...state.postsState, actionState: completeReducer({}) },
});

const addPostAttachmentSuccessReducer = (state: EventsState, action: BaseAction): EventsState => {
    const { postId, attachments } = action.payload;
    const posts = { ...state.postsState.items };
    const post = { ...state.postsState.items[postId] };
    if (!post.attachments) {
        post.attachments = {};
    }
    attachments.forEach((attachment: { file: FileObj }) => {
        post.attachments[attachment.file.id] = attachment.file;
    });
    posts[postId] = post;
    return {
        ...state,
        postsState: { ...state.postsState, items: posts, actionState: completeReducer({}) },
    };
};

const postsDeleteSuccessReducer = (state: EventsState): EventsState => ({
    ...state,
    postsState: { ...state.postsState, actionState: completeReducer({ shouldReload: true }) },
});

const createPostSuccessReducer = (
    state: EventsState,
    actions: BaseAction<{ event: EventsObject; isFinalized: boolean }>,
) => ({
    ...state,
    postsState: {
        ...state.postsState,
        items: {
            [actions.payload.event.post.id]: actions.payload.event.post,
            ...state.postsState.items,
        },
        count: state.postsState.count + 1,
        ...(actions.payload.isFinalized && { actionState: completeReducer({}) }),
    },
});

const clearPostActionReducer = (state: EventsState): EventsState => ({
    ...state,
    postsState: { ...state.postsState, actionState: staleReducer({}) },
});

const clearEventPostActionStates = (state: EventsState): EventsState => ({
    ...state,
    postsState: { ...state.postsState, actionState: staleReducer({}) },
    eventsState: { ...state.eventsState, actionState: staleReducer({}) },
});

const updatePostSuccessReducer = (state: EventsState, actions: BaseAction) => {
    const {
        payload: { event },
    } = actions;
    const { postsState } = state;

    const newPosts = { ...postsState.items };
    newPosts[event.post.id] = event.post;

    return {
        ...state,
        postsState: {
            ...postsState,
            items: newPosts,
            ...(actions.payload.isFinalized && { actionState: completeReducer({}) }),
        },
    };
};

const updateEventPostsReducer = (state: EventsState, actions: BaseAction) => {
    let postItems: Record<string, PostsObject> = state.postsState.items;
    let eventItems: Record<string, EventsObject> = state.eventsState.items;

    const { payload } = actions;
    const { event, notificationType } = payload;

    switch (notificationType) {
        case EventNotificationTypes.NEW_EVENT:
            if (event.eventType === 'post') {
                postItems = {
                    [event.post.id]: event.post,
                    ...state.postsState.items,
                };
            } else {
                eventItems = {
                    [event.id]: event,
                    ...state.eventsState.items,
                };
            }
            break;
        case EventNotificationTypes.UPDATED_POST:
        case EventNotificationTypes.NEW_REPLY:
            if (eventItems[event.id] || postItems[event.id]) {
                if (event.eventType === 'post') {
                    postItems = { ...state.postsState.items };
                    postItems[event.post.id] = event.post;
                } else {
                    eventItems = { ...state.eventsState.items };
                    eventItems[event.id] = event;
                }
            }
            break;
        case EventNotificationTypes.DELETED_POST:
            postItems = { ...state.postsState.items };

            if (event in postItems) {
                delete postItems[event];
            }
            break;
        default:
            break;
    }

    return {
        ...state,
        eventsState: {
            ...state.eventsState,
            items: eventItems,
        },
        postsState: {
            ...state.postsState,
            items: postItems,
        },
    };
};

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(LIST_JOBS)]: listJobsPendingReducer,
    [typeComplete(LIST_JOBS)]: listJobsSuccessReducer,
    [typeFail(LIST_JOBS)]: listJobsFailReducer,
    [typeComplete(SET_JOB_SEARCH_TERM)]: setJobSearchTermReducer,
    [typeComplete(CLEAR_JOB_LIST)]: clearJobListReducer,
    [CREATE_OR_UPDATE_JOB]: createOrUpdateJob,
    [REFRESH_STATE]: resetEventsStateReducer,
    [typePending(LOAD_EVENTS)]: listEventsPendingReducer,
    [typeComplete(LOAD_EVENTS)]: listEventsSuccessReducer,
    [typeFail(LOAD_EVENTS)]: listEventsFailReducer,
    [typeComplete(CLEAR_EVENTS)]: clearEventsReducer,
    [typePending(LOAD_POSTS)]: listPostsPendingReducer,
    [typeComplete(LOAD_POSTS)]: listPostsSuccessReducer,
    [typeFail(LOAD_POSTS)]: listPostsFailReducer,
    [typeComplete(CLEAR_POSTS)]: clearPostsReducer,
    [typePending(DELETE_POSTS)]: postsActionStatePendingReducer,
    [typeComplete(DELETE_POSTS)]: postsDeleteSuccessReducer,
    [typeFail(DELETE_POSTS)]: postsActionStateFailReducer,
    [typePending(CREATE_POST)]: postsActionStatePendingReducer,
    [typeComplete(CREATE_POST)]: createPostSuccessReducer,
    [typeFail(CREATE_POST)]: postsActionStateFailReducer,
    [typePending(ADD_POST_ATTACHMENTS)]: postsActionStatePendingReducer,
    [typeComplete(ADD_POST_ATTACHMENTS)]: addPostAttachmentSuccessReducer,
    [typeFail(ADD_POST_ATTACHMENTS)]: postsActionStateFailReducer,
    [CLEAR_POST_ACTION]: clearPostActionReducer,
    [CLEAR_EVENT_POST_ACTION_STATES]: clearEventPostActionStates,
    [typePending(DOWNLOAD_POST_ATTACHMENT)]: postsActionStatePendingReducer,
    [typeComplete(DOWNLOAD_POST_ATTACHMENT)]: postsActionStateSuccessReducer,
    [typeFail(DOWNLOAD_POST_ATTACHMENT)]: postsActionStateFailReducer,
    [typePending(ADD_EVENT_REPLY)]: updateEventPendingReducer,
    [typeComplete(ADD_EVENT_REPLY)]: updateEventSuccessReducer,
    [typeFail(ADD_EVENT_REPLY)]: updateEventFailReducer,
    [typePending(LOAD_LINKED_OBJECTS)]: postsActionStatePendingReducer,
    [typeComplete(LOAD_LINKED_OBJECTS)]: loadLinkedObjectsSuccessReducer,
    [typeFail(LOAD_LINKED_OBJECTS)]: postsActionStateFailReducer,
    [typePending(UPDATE_POST)]: postsActionStatePendingReducer,
    [typeComplete(UPDATE_POST)]: updatePostSuccessReducer,
    [typeFail(UPDATE_POST)]: postsActionStateFailReducer,

    [UPDATE_EVENT_POSTS]: updateEventPostsReducer,
});
