import { LOAD_STATUS_COMPLETE } from 'state-domains/constants';
import { BaseAction } from 'state-domains/types';
import {
    CLEAR_IMAGO_USER_CONTEXT,
    IMAGO_SET_CONNECTION_STATUS,
    IMAGO_SYNC,
    IMAGO_USER_CONTEXT,
    REFRESH_STATE,
    IMAGO_LOGIN,
    IMAGO_DISCONNECT,
    CLEAR_IMAGO_LOGIN_STATE,
    EVO_CONFIGURATION,
    LOAD_EVO_ORG,
    LOAD_DOWNHOLE_COLLECTIONS,
    CHECK_EVO_PERMISSIONS,
} from 'state-domains/types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    staleReducer,
    typeComplete,
    typeFail,
    typePending,
} from 'state-domains/utils';

import { IntegrationConnectionStatus, IntegrationState } from '../types';
import { addImagoResultToStateCollection } from '../utils';

const INTIAL_ASYNC_STATE = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
};

const INITIAL_CONTEXT_STATE = {
    data: {},
    status: LOAD_STATUS_COMPLETE,
    error: null,
};

export const INITIAL_STATE: IntegrationState = {
    imago: {
        loginState: INTIAL_ASYNC_STATE,
        connectionState: INTIAL_ASYNC_STATE,
        connectionStatus: String(IntegrationConnectionStatus.DISCONNECTED),
        imageriesCount: 0,
        syncState: INTIAL_ASYNC_STATE,
        userContextState: INITIAL_CONTEXT_STATE,
    },
    evoState: {
        orgState: {
            ...INTIAL_ASYNC_STATE,
            orgId: '',
            orgNotes: [],
        },
        downholeState: {
            ...INTIAL_ASYNC_STATE,
            downholeCollections: [],
        },
        configurationState: {
            ...INTIAL_ASYNC_STATE,
            orgId: '',
            projectsMap: {},
            orgNotes: [],
            hubUrl: '',
        },
        checkPermission: {
            ...INTIAL_ASYNC_STATE,
            hasPermission: false,
        },
    },
};

const modifyImagoConnectionPendingReducer = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        connectionState: pendingReducer({}),
    },
});

const modifyImagoConnectionCompleteReducer = (state: IntegrationState, action: BaseAction) => {
    const response = action.payload;
    const imagoIntegrationState: { connectionStatus: string } = { connectionStatus: '' };

    for (const item of response) {
        const { result, actionName } = item;
        addImagoResultToStateCollection(actionName, result, imagoIntegrationState);
    }

    return {
        ...state,
        imago: {
            ...state.imago,
            ...imagoIntegrationState,
            connectionState: completeReducer({}),
        },
    };
};

const modifyImagoConnectionFailureReducer = (state: IntegrationState, action: BaseAction) => {
    const { error, result } = action.payload;

    const updatedResult = result || state.imago.connectionStatus;

    return {
        ...state,
        imago: {
            ...state.imago,
            connectionStatus: updatedResult,
            connectionState: failureReducer({ error }),
        },
    };
};

const syncImagoPendingReducer = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        syncState: pendingReducer({}),
    },
});

const syncImagoCompleteReducer = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        syncState: completeReducer({}),
        imageriesCount: action.payload,
    },
});

const syncImagoFailureReducer = (state: IntegrationState, action: BaseAction) => {
    const { error } = action.payload;

    return {
        ...state,
        imago: {
            ...state.imago,
            syncState: failureReducer({ error }),
        },
    };
};

const imagoUserContextPendingReducer = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        userContextState: pendingReducer({}),
    },
});

const imagoUserContextCompleteReducer = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        userContextState: completeReducer({
            data: action.payload,
            status: LOAD_STATUS_COMPLETE,
            error: null,
        }),
    },
});

const imagoUserContextFailureReducer = (state: IntegrationState, action: BaseAction) => {
    const { error } = action.payload;

    return {
        ...state,
        imago: {
            ...state.imago,
            userContextState: failureReducer({ error }),
        },
    };
};

const resetIntegrationStateReducer = (_state: IntegrationState, _action: BaseAction) => ({
    ...INITIAL_STATE,
});

const clearImagoUserContextState = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        userContextState: INITIAL_CONTEXT_STATE,
    },
});

const imagoLoginPending = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        loginState: pendingReducer({}),
    },
});

const imagoLoginComplete = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        loginState: completeReducer({}),
    },
});

const imagoLoginFail = (state: IntegrationState, action: BaseAction) => {
    const { error } = action.payload;

    return {
        ...state,
        imago: {
            ...state.imago,
            loginState: failureReducer({ error }),
        },
    };
};

const clearImagoLoginState = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    imago: {
        ...state.imago,
        loginState: staleReducer({}),
    },
});

const evoConfigurationComplete = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        configurationState: completeReducer({ ...action.payload }),
    },
});

const evoConfigurationPending = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        configurationState: pendingReducer({ ...state.evoState.configurationState }),
    },
});

const evoConfigurationFailed = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        configurationState: failureReducer({ error: action.payload }),
    },
});

const evoOrgComplete = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        orgState: completeReducer({ ...action.payload }),
    },
});

const evoOrgPending = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        orgState: pendingReducer({ orgId: '', orgNotes: '' }),
    },
});

const evoOrgFailed = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        orgState: failureReducer({ error: action.payload }),
    },
});

const loadDownholeCollectionComplete = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        downholeState: completeReducer(action.payload),
    },
});

const loadDownholeCollectionPending = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        downholeState: pendingReducer({ downholeCollections: [] }),
    },
});

const loadDownholeCollectionFailed = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        downholeState: failureReducer({ error: action.payload }),
    },
});

const checkEvoPermissionComplete = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        checkPermission: completeReducer({ hasPermission: action.payload }),
    },
});

const checkEvoPermissionPending = (state: IntegrationState, _action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        checkPermission: pendingReducer({ hasPermission: false }),
    },
});

const checkEvoPermissionFailed = (state: IntegrationState, action: BaseAction) => ({
    ...state,
    evoState: {
        ...state.evoState,
        checkPermission: failureReducer({ error: action.payload }),
    },
});

export const reducer = mappedReducer(INITIAL_STATE, {
    [typeComplete(IMAGO_SET_CONNECTION_STATUS)]: modifyImagoConnectionCompleteReducer,
    [typePending(IMAGO_SET_CONNECTION_STATUS)]: modifyImagoConnectionPendingReducer,
    [typeFail(IMAGO_SET_CONNECTION_STATUS)]: modifyImagoConnectionFailureReducer,
    [typeComplete(IMAGO_SYNC)]: syncImagoCompleteReducer,
    [typePending(IMAGO_SYNC)]: syncImagoPendingReducer,
    [typeFail(IMAGO_SYNC)]: syncImagoFailureReducer,
    [typeComplete(IMAGO_USER_CONTEXT)]: imagoUserContextCompleteReducer,
    [typePending(IMAGO_USER_CONTEXT)]: imagoUserContextPendingReducer,
    [typeFail(IMAGO_USER_CONTEXT)]: imagoUserContextFailureReducer,
    [REFRESH_STATE]: resetIntegrationStateReducer,
    [typeComplete(CLEAR_IMAGO_USER_CONTEXT)]: clearImagoUserContextState,
    [typePending(IMAGO_LOGIN)]: imagoLoginPending,
    [typeComplete(IMAGO_LOGIN)]: imagoLoginComplete,
    [typeFail(IMAGO_LOGIN)]: imagoLoginFail,
    [typePending(IMAGO_DISCONNECT)]: imagoLoginPending,
    [typeComplete(IMAGO_DISCONNECT)]: imagoLoginComplete,
    [typeFail(IMAGO_DISCONNECT)]: imagoLoginFail,
    [CLEAR_IMAGO_LOGIN_STATE]: clearImagoLoginState,

    [typeComplete(EVO_CONFIGURATION)]: evoConfigurationComplete,
    [typePending(EVO_CONFIGURATION)]: evoConfigurationPending,
    [typeFail(EVO_CONFIGURATION)]: evoConfigurationFailed,
    [typeComplete(LOAD_EVO_ORG)]: evoOrgComplete,
    [typePending(LOAD_EVO_ORG)]: evoOrgPending,
    [typeFail(LOAD_EVO_ORG)]: evoOrgFailed,
    [typeComplete(LOAD_DOWNHOLE_COLLECTIONS)]: loadDownholeCollectionComplete,
    [typePending(LOAD_DOWNHOLE_COLLECTIONS)]: loadDownholeCollectionPending,
    [typeFail(LOAD_DOWNHOLE_COLLECTIONS)]: loadDownholeCollectionFailed,
    [typeComplete(CHECK_EVO_PERMISSIONS)]: checkEvoPermissionComplete,
    [typePending(CHECK_EVO_PERMISSIONS)]: checkEvoPermissionPending,
    [typeFail(CHECK_EVO_PERMISSIONS)]: checkEvoPermissionFailed,
});
