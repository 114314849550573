import { AsyncState, AsyncStateError, LoadStatus } from 'state-domains/types';

import { Drillhole } from '../drillhole';
import { MXDTimestamp } from '../utils';

export interface IntegrationState {
    imago: ImagoIntegration;
    evoState: {
        orgState: {
            orgId: string;
            orgNotes: any[];
        } & AsyncState;
        downholeState: {
            downholeCollections: any[];
        } & AsyncState;
        configurationState: {
            orgId: string;
            projectsMap: Record<string, any>;
            orgNotes: any[];
            hubUrl: string;
        } & AsyncState;
        checkPermission: {
            hasPermission: boolean;
        } & AsyncState;
    };
    [index: string]: any;
}

export enum INTEGRATION_STATES {
    IMAGO = 'IMAGO',
    EVO = 'EVO',
}

export enum INTEGRATION_SUB_STATES {
    ORG_STATE = 'ORG_STATE',
    WORKSPACE_STATE = 'WORKSPACE_STATE',
    CONFIGURATION = 'CONFIGURATION',
    STATE = 'STATE',
    LOGIN_STATE = 'LOGIN_STATE',
    CONNECTION_STATUS = 'CONNECTION_STATUS',
    IMAGERIES_COUNT = 'IMAGERIES_COUNT',
    CONNECTION_STATE = 'CONNECTION_STATE',
    SYNC_STATE = 'SYNC_STATE',
    USER_CONTEXT_STATE = 'USER_CONTEXT_STATE',
    EVO_CONFIGURATION = 'EVO_CONFIGURATION',
}

// #region ========================= Imago =========================

export interface ImagoContextState {
    data: any;
    status: LoadStatus;
    error: AsyncStateError | null;
}

export interface ImagoIntegration {
    loginState: AsyncState;
    connectionStatus: string;
    imageriesCount: number;
    connectionState: AsyncState;
    syncState: AsyncState;
    userContextState: ImagoContextState;
}

export interface ImagoLoginCredentials {
    username: string;
    password: string;
}

export interface ImagoConnectionResponse {
    value: string;
}

export enum IntegrationConnectionStatus {
    CONNECTED = 'connected',
    EXPIRED = 'expired',
    DISCONNECTED = 'disconnected',
}

export enum ImagoSyncType {
    IMAGES = 'sync-images',
    INTERVALS = 'sync-intervals',
}

export interface ImagoSyncResponse {
    Collar: Drillhole;
    imageriesCount: number;
}

// #endregion ========================= Imago =========================

// #region ========================= Payloads =========================

export interface ImagoSyncPayload {
    activity: string;
    collar: string;
    collar_name: string;
    column: string;
    project: string;
    table: string;
    tableview: string;
}
// #endregion ========================= Payloads =========================

// #region ========================= Evo =========================

export interface EvoWorkspaceReponseState {
    workspaces: WorkspaceEvoState[];
    workspacesMap: Record<string, WorkspacesMapState>;
    links: WorkspaceLinksState;
    results: WorkspaceEvoState[];
}

export interface WorkspaceLinksState {
    count: number;
    first: string;
    last: string;
    next: any;
    previous: any;
    total: number;
}

export interface WorkspacesMapState {
    id: string;
    name: string;
}

export interface WorkspaceEvoState {
    boundingBox: any;
    createdBy: string;
    createdAt: MXDTimestamp;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    currentUserRole: string;
    defaultCoordinateSystem: string;
    description: string;
    id: string;
    labels: any[];
    name: string;
    selfLink: string;
}

export interface EvoOrgState {
    orgId: string;
    orgNotes: any[];
}

export interface EvoConfigurationState {
    hubUrl: string;
    orgId: string;
    orgNotes: any[];
    projectsMap: EvoProjectMap;
}

export type EvoProjectMap = Record<string, WorkspacesMapState>;

export interface EvoDiscoveryState {
    discovery: EvoDiscoveryMap;
    serviceAccess: ServiceAccessMap;
}

export interface EvoDiscoveryMap {
    hubs: Record<string, any>[];
    organizations: Record<string, any>[];
    serviceAccess: Record<string, any>[];
    services: Record<string, any>[];
}

export interface ServiceAccessMap {
    hubCode: string;
    hubName: string;
    hubUrl: string;
    orgId: string;
    orgName: string;
    services: string[];
}

export interface DownholeCollectionPayload {
    offset: number;
    limit: number;
    objectName: string;
}

export interface DownholeCollectionItem {
    objectId: string;
    name: string;
    createdBy: string;
    createdAt: string;
}

// #endregion ========================= Evo =========================
