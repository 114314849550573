import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import {
    buildFillDownSaveRowReferencesUrl,
    buildFillDownSaveRowReferencesPointsUrl,
} from '../../../network/urls';
import {
    convertToCamel,
    convertToCamelWithSpecialParameters,
    sendRequestWithXSRFToken,
} from '../../../utils';
import { Drillhole, RowReference, RowReferenceResponse } from '../types';

export const fillDownSaveRowReference = (
    payload: any,
    id: string,
    type: ACTIVITY_TYPES,
): Observable<RowReferenceResponse> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildFillDownSaveRowReferencesUrl(id || '')
            : buildFillDownSaveRowReferencesPointsUrl(id || '');
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<RowReferenceResponse>) => {
            const { RowReference, Collar } = response;
            const convertedReferences = convertToCamelWithSpecialParameters<RowReference[]>(
                RowReference,
                { id: 'uid' },
            );
            const convertedCollar = convertToCamel<Drillhole>(Collar);
            const convertedNextRows = convertToCamelWithSpecialParameters<
                Record<string, RowReference>
            >(response.NextRows ?? {}, { id: 'uid' });
            return observableOf({
                RowReference: convertedReferences,
                Collar: convertedCollar,
                NextRows: convertedNextRows,
            } as RowReferenceResponse);
        }),
    );
};
