import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ValidationRule } from 'state-domains/domain/configuration';

import { buildEditValidationRulesUrl } from '../../../network/urls';
import { sendDeleteRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const deleteConfigurationValidationRule = (id: string): Observable<ValidationRule> =>
    sendDeleteRequestWithXSRFToken(buildEditValidationRulesUrl(id)).pipe(
        switchMap(({ response }: AjaxResponse<ValidationRule>) => {
            const result = convertToCamel<ValidationRule>(response);
            return observableOf(result);
        }),
    );
