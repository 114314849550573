import React from 'react';
import { useSelector } from 'react-redux';
import { pAsTypograhpyFontStyles, spanChipStyles } from 'src/styles';
import { subscriptionState } from 'state-domains/domain/subscription';
import { v4 as uuidv4 } from 'uuid';

export const StringWithUsers = (props: { content: string }) => {
    const { content } = props;
    const {
        selectors: { users: usersSelector },
    } = subscriptionState;

    const users = useSelector(usersSelector);

    const newStr = content.replace(/&nbsp;/g, ' ');
    const regExMatch = /<span\sclass="taggedPerson">.?[A-Za-z0-9\s]+.?<\/span>/g;
    const nonNameStringArr = newStr.split(regExMatch);

    const userArr = React.useMemo(() => {
        const idArr: any[] = [];
        const matchesArr = [];
        let match;
        do {
            match = regExMatch.exec(newStr);
            if (match) {
                matchesArr.push(match[0]);
            }
        } while (match);

        matchesArr.forEach((x) => {
            const userRegexMatch = /<span\sclass="taggedPerson">([^<]+)<\/span>/g;
            let id = (userRegexMatch.exec(x)?.[1] ?? '').toString();
            if (id) {
                id = id.startsWith('@') ? id.substring(1, id.length - 1) : id;
                idArr.push(users[id]?.profile);
            }
        });
        return idArr;
    }, [newStr]);

    return (
        <p style={{ ...pAsTypograhpyFontStyles, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {nonNameStringArr.map((x: string, idx: number) => {
                const parts = x.split(/<br\s*\/?>/g);
                const WithBreaks = (
                    <>
                        {parts.map((p, index) => {
                            return (
                                <React.Fragment key={`part-text-${uuidv4()}`}>
                                    {p}
                                    {index !== parts.length - 1 ? <br /> : <></>}
                                </React.Fragment>
                            );
                        })}
                    </>
                );
                return userArr?.[idx] ? (
                    <React.Fragment key={`user-${uuidv4()}`}>
                        {WithBreaks}
                        <span
                            style={{
                                ...spanChipStyles,
                                backgroundColor: userArr?.[idx].color ?? '#979797',
                            }}
                        >
                            {(userArr?.[idx]?.name ?? '').toUpperCase()}
                        </span>
                    </React.Fragment>
                ) : (
                    <React.Fragment key={`user-${uuidv4()}`}>{WithBreaks}</React.Fragment>
                );
            })}
        </p>
    );
};
