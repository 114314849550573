import { useSelector } from 'react-redux';
import { constructProjectActivityPermissions } from 'src/components/Projects/CustomHooks/useProjectInfo/useProjectInfo.utils';
import {
    EventLinkedObjectType,
    PostLinkedObjectType,
    PROJECT_ACTIVITY_PERMISSIONS,
    projectState,
    subscriptionState,
    userState,
} from 'state-domains/domain';
import { useMemo } from 'react';

import { FilterUsersByPermissionParams } from './Home.types';

export const useFilterUsersByObjectPermission = ({
    projectId,
    activityId,
    objectType,
}: FilterUsersByPermissionParams) => {
    const {
        selectors: { projects: projectsSelector },
    } = projectState;
    const {
        selectors: {
            activities: activitiesSelector,
            defaultPermissions: defaultPermissionsSelector,
            users: usersSelector,
            account: accountSelector,
        },
    } = subscriptionState;
    const {
        selectors: {
            currentUserId: currentUserIdSelector,
            currentSubscription: currentSubscriptionSelector,
        },
    } = userState;

    const users = useSelector(usersSelector);
    const projects = useSelector(projectsSelector);
    const activities = useSelector(activitiesSelector);
    const defaultPermissions = useSelector(defaultPermissionsSelector);
    const currentUserId = useSelector(currentUserIdSelector);
    const currentSubscription = useSelector(currentSubscriptionSelector);
    const account = useSelector(accountSelector);
    const userIds = useMemo(
        () =>
            Object.keys(users).filter((id) => {
                const user = users[id];
                return (
                    user?.type !== 'inactive' &&
                    Object.values(user?.subscriptions ?? {})
                        .filter(Boolean)
                        .some((sub) => {
                            const subscription = account.subscriptions[sub.subscription];
                            return (
                                subscription?.type !== 'inactive' &&
                                (subscription?.expiryDate?.date ?? 0) > Date.now()
                            );
                        })
                );
            }),
        [users, currentSubscription, account],
    );

    return useMemo(() => {
        if (!projectId || !projects[projectId]) {
            return {
                usersWithPermission: userIds,
                usersWithoutPermission: [],
                mentionableUsers: userIds
                    .filter((id) => id !== currentUserId)
                    .map((id) => ({ id, name: users[id].profile.name })),
            }; // All users have permission if a project is not selected
        }
        const project = projects[projectId];
        const usersWithType = userIds.map((id: string) => ({ id, type: users[id].type }));

        const permissionsMap = constructProjectActivityPermissions(
            project,
            activities,
            defaultPermissions,
            usersWithType,
        );

        const usersWithPermission = userIds.filter((user) => {
            // User has project level access if they're a project level user or have access to at least 1 activity
            if (!(user in project.users) && Object.keys(permissionsMap[user]).length === 0)
                return false;
            if (!activityId) return true; // No activity selected

            // Activity level access for the selected activity
            const permissions = permissionsMap[user]?.[activityId]?.permissions;
            if (!(permissions & PROJECT_ACTIVITY_PERMISSIONS.ACTIVITY_FEED)) return false;

            // Object type level access
            if (!objectType) return true; // No object type selected
            switch (objectType) {
                case EventLinkedObjectType.PROJECT:
                    return Object.keys(permissionsMap[user]).length > 0;
                case EventLinkedObjectType.ACTIVITY:
                    return !!permissionsMap[user]?.[activityId];
                case PostLinkedObjectType.DRILLHOLE:
                case PostLinkedObjectType.POINT:
                case EventLinkedObjectType.DRILLHOLE:
                case EventLinkedObjectType.POINT:
                    return permissions & PROJECT_ACTIVITY_PERMISSIONS.COLLAR;
                case PostLinkedObjectType.DISPATCH:
                case EventLinkedObjectType.DISPATCH:
                    return permissions & PROJECT_ACTIVITY_PERMISSIONS.SAMPLE_DISPATCH;
                case PostLinkedObjectType.LAB_CERT:
                case EventLinkedObjectType.LAB_CERT:
                    return permissions & PROJECT_ACTIVITY_PERMISSIONS.ASSAYS_QAQC;
                default:
                    return false;
            }
        });

        const usersWithoutPermission = userIds.filter((id) => !usersWithPermission.includes(id));

        const mentionableUsers = usersWithPermission
            .filter((id) => id !== currentUserId)
            .map((id) => ({ id, name: users[id].profile.name }));

        return { usersWithPermission, usersWithoutPermission, mentionableUsers };
    }, [
        projectId,
        activityId,
        objectType,
        projects,
        activities,
        defaultPermissions,
        users,
        currentUserId,
        userIds,
    ]);
};
