import { Backdrop, Dialog, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { i18nActionConstants } from 'state-domains/constants/mxdeposit.i18n';
import { AsyncState } from 'state-domains/types';
import React from 'react';

import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { DialogWarningNotification, DialogErrorNotification } from './DialogNotifications';
import { DialogProgressBar } from './DialogProgressBar';
import { DialogWithHeaderProps } from './DialogWithHeader.types';

export const DialogWithHeader = (props: DialogWithHeaderProps) => {
    const emptyObjectRef = React.useRef({});
    const {
        open,
        disableEscapeKeyDown = true,
        dialogWidth = '438px',
        title,
        cancelLabel,
        applyLabel,
        onClose,
        onApply,
        isPending = false,
        applyDisabled,
        asyncState = () => emptyObjectRef.current as AsyncState,
        saveInitiated = false,
        checkboxText,
        currentCheckboxStateRef = { current: false },
        numTimesAppliedRef = { current: 0 },
        children,
        warning = '',
        showFooter = true,
    } = props;
    const intl = useIntl();
    const [checked, setChecked] = React.useState(false);
    const handleCheckBoxChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        currentCheckboxStateRef.current = event.target.checked;
    }, []);

    const onApplyFn = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            numTimesAppliedRef.current += 1;
            onApply(event);
        },
        [onApply],
    );

    return (
        <Grid>
            {open && (
                <Dialog
                    open={open}
                    PaperProps={{ square: true }}
                    onClose={onClose}
                    disableEscapeKeyDown={disableEscapeKeyDown}
                    maxWidth="lg"
                >
                    <Grid sx={{ width: dialogWidth }}>
                        <Backdrop
                            sx={{ backgroundColor: 'transparent', zIndex: 99 }}
                            open={isPending}
                        />
                        <DialogHeader title={title} onClick={onClose} />
                        {children}
                        <DialogErrorNotification state={asyncState} saveInitiated={saveInitiated} />
                        <DialogWarningNotification warningMessage={warning} />
                        {showFooter && (
                            <DialogFooter
                                handleClose={onClose}
                                handleApply={onApplyFn}
                                applyDisabled={applyDisabled}
                                cancelLabel={
                                    cancelLabel || intl.formatMessage(i18nActionConstants.cancel)
                                }
                                applyLabel={
                                    applyLabel || intl.formatMessage(i18nActionConstants.apply)
                                }
                                checkboxText={checkboxText}
                                checkboxState={checked}
                                handleCheckBoxChange={handleCheckBoxChange}
                            />
                        )}
                        <DialogProgressBar isPending={isPending} />
                    </Grid>
                </Dialog>
            )}
        </Grid>
    );
};
