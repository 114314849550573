import { theme } from '@local/web-design-system';
import { Button, Divider, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { useStyles } from './DialogWithHeader.styles';

export const DialogFooter = (props: {
    handleClose: React.MouseEventHandler<HTMLButtonElement>;
    handleApply: React.MouseEventHandler<HTMLButtonElement>;
    applyDisabled: boolean;
    cancelLabel: string;
    applyLabel: string;
    checkboxText?: string;
    checkboxState?: boolean;
    handleCheckBoxChange?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
    const {
        handleClose,
        handleApply,
        applyDisabled,
        cancelLabel,
        applyLabel,
        checkboxText,
        checkboxState,
        handleCheckBoxChange,
    } = props;
    const { classes } = useStyles();
    return (
        <>
            {checkboxText && (
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    sx={{ height: theme.spacing(5) }}
                >
                    <Grid sx={{ marginLeft: theme.spacing(1.25) }}>
                        <Checkbox
                            checked={checkboxState}
                            onChange={handleCheckBoxChange}
                            inputProps={{ 'aria-label': 'checkbox' }}
                            size="small"
                        />
                    </Grid>
                    <Grid>
                        <Typography className={classes.checkboxLabel}>{checkboxText}</Typography>
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <Divider />
            </Grid>
            <Grid item container padding={theme.spacing(2, 2)} sx={{ width: '100%' }}>
                <Grid item sx={{ flex: 1 }}>
                    <Button
                        color="secondary"
                        fullWidth
                        variant="contained"
                        sx={{ height: `${theme.spacing(5)}` }}
                        onClick={handleClose}
                        disabled={false}
                    >
                        {cancelLabel}
                    </Button>
                </Grid>
                <Grid sx={{ width: theme.spacing(1) }} />
                <Grid item sx={{ flex: 1 }}>
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={handleApply}
                        disabled={applyDisabled}
                        sx={{ height: theme.spacing(5) }}
                    >
                        {applyLabel}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
